module.exports = {
  // FORM LEVEL CONFIG
  // Required for backend - Format: <RoleID>:<Role API Token>
  roleToken: "13972:ecc05231cec242a6998304f8af29e387",
  // Required for backend - Marketplace unique hash
  // https://mediavoice.com/admin/distribution/marketplace/
  marketplace: "bb7d024f02644ae7a1f10952259fc1e0",
  currency: "eur",
  VAT: 0.2,
  // Used as the page title
  pageTitle: "M Publicité Order Form",
  // Header image clickout
  imageLink: "https://polar.me/mpublicite",
  toEmails: ["kendall.schultz@polar.me","jovana.ivanovic@polar.me", "traffic@mpublicite.fr"],

  // CAMPAIGN DETAILS
  // Turns on the social post ownership checkbox (NA-8879)
  socialOwnershipCheckboxEnabled: false,
  previewToolEnabled: false,
  previewToolURL: "",
  // Dates
  dateSliderEnabled: true,
  dateSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day",
    color: "grey",
    start: 3,
    min: 3,
    max: 14,
    step: 1
  },
  durationSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " jours",
    color: "grey",
    start: 7,
    min: 5,
    max: 30,
    step: 1
  },
  // Label shown below date fields
  dateSubheader: "",
  // Budget/CPM
  budgetSliderEnabled: true,
  budgetSliderSettings: {
    labelBeforeValue: "€",
    labelAfterValue: " ",
    color: "grey",
    start: 1000,
    min: 500,
    max: 12000,
    step: 100
  },
  // Enables the estimated impressions text below the budget field
  impressionLabelEnabled: true,
  cpm: 3,
  // Budget field subheader
  fixedCPMLabel: " total spend",
   // Budget Promotions (NA-8912)
    // Promo percentage should be an integer, it will be converted and applied to the impressions count
    promoPercentage: 10,
    // In order to work, start and end dates must be in MM/DD/YYYY format
    // Single digits will work fine (ie 1 for January)
    promoStartDate: "11/08/2019",
    promoEndDate: "12/01/2019",


  // TARGETING
  // All targeting options are in the format of:
  // label: Visual label in the UI
  // Key: corresponding segment or site ID in the hub admin
  // Audience Targeting
  audiences: [
    { label: "Actu", key: "a0976f04289d4ce3a0617c94e4622ab8"},
    { label: "Business", key: "3479d8c3f376414d857840c5d8bc8881"},
    { label: "Culture", key: "cf9cd1328b8c46a2b4f4f4f070a1e58b"},
    { label: "High-tech", key: "514e8fbd9d254a9a9c88636f0d4c3d4a"},
    { label: "Lifestyle", key: "8deb6c5160194f69a3c38f7001736d51"},
    { label: "Sport", key: "1d538148049d430bb5bca45b341b0296"},
    { label: "Voyage", key: "ce6c4be176d549d8a404212f6b43aee4"},
  ],

  audienceEnabled: true,
  // Used as the UI Header for the audience targeting section
  audienceTitle: "Content",

  // Interest Targeting
  interests: [
    { label: "Arts & Entertainment", key: "Arts & Entertainment" },
    { label: "Autos & Vehicles", key: "Autos & Vehicles" },
    { label: "Beauty & Fitness", key: "Beauty & Fitness" },
  ],
  interestEnabled: false,

  // Site Targeting
  sites: [
    { label: "Courrier International", key: "" },
    { label: "Huffington Post FR", key: "" },
    { label: "Le Monde", key: "" },
    { label: "L'Obs", key: "" },
    { label: "Telerama", key: "" }
  ],
  siteEnabled: false,

  // Geo Targeting
  geos: [
    { label: "France", key: "f1b063229f5c4e5fa4a48f381e76bf00" },
    // { label: "Belgium", key: "d7bccc6890fe4fea8eee6c79bed07af1" },
    // { label: "Canada", key: "e99f24f0d755413d8590eb2f7370044a" },
    // { label: "Italy", key: "ac750e72364940e5871b546de33c3061" },
    // { label: "Switzerland", key: "857235eece1c419a9eaabc4cf751cd31" },
    // { label: "UK", key: "9b016e834bdb417b9f5793d4714b59a0" },
    // { label: "US", key: "0cba7b5324694ff89a31cbd4809e0c70" },
    { label: "Auvergne-Rhône-Alpes", key: "c6f5d0a0f03f446eab06b61f3cac2cc4" },
    { label: "Bourgogne-Franche-Comté", key: "0a9018485e644352b05011fd5e0d0f04" },
    { label: "Bretagne", key: "07008e7c9f224c869df0b794bc0781cc" },
    { label: "Centre-Val de Loire", key: "e23acf2d6faa4287be591ae07c6407b5" },
    { label: "Corse", key: "98458cf70dd34c85bb39f8db1895e1fa" },
    { label: "Grand Est", key: "e74a84e6ec0049fa833aab3503c3c95b" },
    { label: "Hauts-de-France", key: "694ec82b9c0143e7a670c8485173d556" },
    { label: "Île-de-France", key: "254f362ce18b44468303ad930fe06315" },
    { label: "Normandie", key: "550f540eb5ec42ec9e62401b5700be05" },
    { label: "Nouvelle-Aquitaine", key: "30b53d0044c24c6590e970e86529f3e6" },
    { label: "Occitanie", key: "b300e7cf92214c14a1775378e0f18457" },
    { label: "Pays de la Loire", key: "e7a9ff5b6ad8413cb9d375b3d7a18278" },
    { label: "Provence-Alpes-Côte d'Azur", key: "1f6df83609114aa4983b563bcce4cbc3" }
  ],
  geoEnabled: true,
  // Age and  Gender Targeting
  // Values are hardcoded in constants.js and do not change per-form
  ageEnabled: false,
  genderEnabled: false,

  // ADDITIONAL INFORMATION
  additionalInformationEnabled: true,
  additionalInformationSubheader: "Vous pouvez fournir des trackings (Impressions ou clics) ou toute information complémentaire",

  // PAYMENT
  stripeEnabled: true,
  // Text rendered below the payment field
  paymentLabel:
    "Vous serez débité de Polar Inc. sur votre Carte de Crédit au début de votre campagne",
  // Account ID field Subheader
  // Only used when stripe is not enabled
  accountIdSubheader: "",
  // Disable the payment field altogether
  disablePayment: false,

  // TERMS AND CONDITIONS
  tacEnabled: true,
  // Enables rendering T&C as HTML
  // If true, the T&C must be defined as an HTML object in the htmlTermsAndConditions object inside TermsAndConditionsModal.jsx
  tacHTML: true,
  // Used as the terms and conditions text when tacHTML is false
  termsAndConditions: "",

  // Footer displayed below submit button
  partnerMessage: ""
};
