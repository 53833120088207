import React, { Component } from "react";
import PropTypes from "prop-types";

export default class HeaderImage extends Component {
  static propTypes = {
    partnerName: PropTypes.string.isRequired
  };

  get imgSrc() {
    // Import Form image based on partnerName
    return require(`../formData/${this.props.partnerName}/logo.png`);
  }

  render() {
    return (
      <a
        className="logoLink"
        href={this.props.imageLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="logo" alt="" src={this.imgSrc} />
      </a>
    );
  }
}
