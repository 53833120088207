module.exports = {
    // FORM LEVEL CONFIG
    // Required for backend - Format: <RoleID>:<Role API Token>
    roleToken: "14454:87c318d4b6f64cdbbbefa56d7a17ae9a",
    // Required for backend - Marketplace unique hash
    // https://mediavoice.com/admin/distribution/marketplace/
    marketplace: "3be9db9b81c14df2aa974cd60d4238de",
    // Must be a stripe supported currency code
    // See: https://stripe.com/docs/currencies
    currency: "gbp",
    // Used as the page title
    pageTitle: "Mail Online Order Form",
    // Header image clickout
    imageLink: "https://polar.me/mailmetro",
    toEmails: ["adops@mailonline.co.uk", "kendall@polar.me"],    

    // CAMPAIGN DETAILS
    previewToolEnabled: false,
    previewToolURL: "",
    // Dates
    europeanDate:true,
    dateSliderEnabled: false,
    dateSliderSettings: {
        labelBeforeValue: "",
        labelAfterValue: " day",
        color: "grey",
        start: 1,
        min: 1,
        max: 7,
        step: 1,
    },
    durationSliderSettings: {
        labelBeforeValue: "",
        labelAfterValue: " day total",
        color: "grey",
        start: 3,
        min: 3,
        max: 30,
        step: 1,
    },
    // Label shown below date fields
    dateSubheader: "",
    // Budget/CPM
    budgetSliderEnabled: true,
    budgetSliderSettings: {
        labelBeforeValue: "£",
        labelAfterValue: " estimated total spend",
        color: "grey",
        start: 2,
        min: 300,
        max: 3000,
        step: 100,
    },
    // Enables budget radio options 
    budgetRadioEnabled: false,
    // Budget Options will use budget and cpm values for calculations
    // and will submit to the backend. By default, the middle option is selected
    budgetRadioOptions: [
        {
            budget: 1000,
            cpm: 25,
            label: "$1,000 (40,000 ads, duration of 2 weeks)"
        },
        {
            budget: 2500,
            cpm: 20,
            label: "$2,500 (125,000 ads, duration of 4 weeks)"
        },
        {
            budget: 5000,
            cpm: 16.66,
            label: "$5,000 (300,000 ads, duration of 6 weeks)"
        },
    ],
    // Enables the estimated impressions text below the budget field
    impressionLabelEnabled: true,
    cpm: 10,
    // Budget field subheader
    fixedCPMLabel: "Fixed Rate CPM of £10.",


    // TARGETING
    // All targeting options are in the format of:
    // label: Visual label in the UI
    // Key: corresponding segment or site ID in the hub admin
    // Audience Targeting
    audiences: [
    ],
    audienceEnabled: true,
    // Used as the UI Header for the audience targeting section
    audienceTitle: "Content Targeting",
    audienceTargetingNotesEnabled: false,

    // Interest Targeting
    interests: [
        { label: "Arts & Entertainment", key: "Arts & Entertainment" },
        { label: "Autos & Vehicles", key: "Autos & Vehicles" },
        { label: "Beauty & Fitness", key: "Beauty & Fitness" },
        { label: "Business, Finance & Industrial", key: "Business, Finance & Industrial" },
        { label: "Computers & Electronics", key: "Computers & Electronics" },
        { label: "Food, Drink & Health", key: "Food, Drink & Health" },
        { label: "Home, Garden & Real Estate", key: "Home, Garden & Real Estate" },
        { label: "Law & Government", key: "Law & Government" },
        { label: "News", key: "News" },
        { label: "Shopping & Retail", key: "Shopping & Retail" },
        { label: "Sports", key: "Sports" },
        { label: "Travel", key: "Travel" },
    ],
    interestEnabled: false,

    // Site Targeting
    sites: [
        {label: "Daily Mail", key: "a7bad61adbc043a58fc328b950635e03"},
        {label: "Metro", key: "833641e1b59e45d3b086838f345c6eb4"}
    ],
    siteEnabled: true,

    // Geo Targeting
    geos: [
        {label: "UK", key: "4472082c07ae47b99a62b2e6a2bc5696"},
        {label: "Local", key: "8310915d8d624a6fb90e0be19d4e9880"},
        {label: "Scotland", key: "46c47c6eea8b49f88aa42aaaf08c6c89"},
        {label: "Wales", key: "600fd7bd1eab4c6faad24d7587ae8962"},
        {label: "Northern Ireland", key: "12da18965c2e41d3850059e53130f61c"},
        {label: "Ireland", key: "c0c759364d6643cd87ea71335cc0f2d2"}
    ],
    geoEnabled: true,
    // Age and  Gender Targeting
    // Values are hardcoded in constants.js and do not change per-form
    ageEnabled: false,
    genderEnabled: false,
    
    // ADDITIONAL INFORMATION
    additionalInformationEnabled: true,
    additionalInformationSubheader: "Provide click, impression trackers or other comments.",


    // PAYMENT
    stripeEnabled: false,
    // Text rendered below the payment field
    paymentLabel: "",
    // Account ID field Subheader
    // Only used when stripe is not enabled
    accountIdSubheader: "",
    disablePayment: true,
    
    // TERMS AND CONDITIONS
    tacEnabled: true,
    // Enables rendering T&C as HTML
    // If true, the T&C must be defined as an HTML object in the htmlTermsAndConditions object inside TermsAndConditionsModal.jsx
    tacHTML: false,
    // Used as the terms and conditions text when tacHTML is false
    termsAndConditions: "This Order is governed by the IAB's Standard Terms and Conditions for Internet Advertising for Media Buys.",

    // Footer displayed below submit button
    partnerMessage: "",

    // NA-9627
    contentTargetingTextInputEnabled: true,
}