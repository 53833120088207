module.exports = {
  // FORM LEVEL CONFIG
  // Required for backend - Format: <RoleID>:<Role API Token>
  roleToken: "13690:11b9e023dc464ff79f2c86b26265c22d",
  // Required for backend - Marketplace unique hash
  // https://mediavoice.com/admin/distribution/marketplace/
  marketplace: "a4b83fd4e9c043868ad8bed3cc63bc57",
  // Must be a stripe supported currency code
  // See: https://stripe.com/docs/currencies
  currency: "usd",
  // Used as the page title
  pageTitle: "Polar Direct Order Form",
  // Header image clickout
  imageLink: "https://polar.me/direct",
  toEmails: ["nicole.fung@polar.me", "al@polar.me"],

  // CAMPAIGN DETAILS
  previewToolEnabled: false,
  previewToolURL: "",
  // Dates
  dateSliderEnabled: true,
  dateSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day",
    color: "grey",
    start: 1,
    min: 1,
    max: 7,
    step: 1
  },
  durationSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day total",
    color: "grey",
    start: 7,
    min: 3,
    max: 30,
    step: 1
  },
  // Label shown below date fields
  dateSubheader: "",
  // Budget/CPM
  budgetSliderEnabled: true,
  budgetSliderSettings: {
    labelBeforeValue: "$",
    labelAfterValue: " estimated total spend",
    color: "grey",
    start: 2500,
    min: 500,
    max: 10000,
    step: 500
  },
  // Enables budget radio options
  budgetRadioEnabled: false,
  // Budget Options will use budget and cpm values for calculations
  // and will submit to the backend. By default, the middle option is selected
  budgetRadioOptions: [
    {
      budget: 1000,
      cpm: 25,
      label: "$1,000 (40,000 ads, duration of 2 weeks)"
    },
    {
      budget: 2500,
      cpm: 20,
      label: "$2,500 (125,000 ads, duration of 4 weeks)"
    },
    {
      budget: 5000,
      cpm: 16.66,
      label: "$5,000 (300,000 ads, duration of 6 weeks)"
    }
  ],
  // Enables the estimated impressions text below the budget field
  impressionLabelEnabled: true,
  cpm: 10,
  // Budget field subheader
  fixedCPMLabel: "Fixed Rate CPM of $10.",

  // TARGETING
  // All targeting options are in the format of:
  // label: Visual label in the UI
  // Key: corresponding segment or site ID in the hub admin
  // Audience Targeting
  audiences: [
    { label: "Business", key: "e47edf1fa03740a9b0d8d2c8ee779575" },
    { label: "Entertainment", key: "c6d7cd383bb54e8895d8d8b8c4c2cc89" },
    { label: "General Consumer", key: "7040f9e212bf425789cbc070e52f1bb3" },
    { label: "Health", key: "05d3bebce78c43e8b663c67d5eb29851" },
    { label: "Lifestyle", key: "e7d6b2788da44fefa897d4eddbc3fa09" },
    { label: "Sports", key: "fb13ff7b8d2c4da4a2887ae45e6ff6b8" },
    { label: "Technology", key: "c25daabd96e04fd3aea59b46bb9152d4" },
    { label: "Travel", key: "d2bc7e4ab4a24b84801155859f5ba9fc" }
  ],
  audienceEnabled: false,
  // Used as the UI Header for the audience targeting section
  audienceTitle: "Content",

  // Interest Targeting
  interests: [
    { label: "Arts & Entertainment", key: "b16e86070e964ff1b450e1e8e376145a" },
    { label: "Autos & Vehicles", key: "20537cb935d1445bb70d43f0f1864d4e" },
    {
      label: "Beauty, Fitness & Health",
      key: "711b349b8fa347839823022ff0f575b7"
    },
    {
      label: "Business, Finance & Industrial",
      key: "913966f332d245f1b9d9daa6489bf001"
    },
    {
      label: "Computers & Electronics",
      key: "f618010c496b451491b0bf4d128ceb8d"
    },
    { label: "Food & Drink", key: "9f843cd9b8ea4a4c8b3b9c9d035f3218" },
    {
      label: "Home, Garden & Real Estate",
      key: "2fed6b44875847d1a0297c0d065d5fcf"
    },
    { label: "Law & Government", key: "1354a20f5e6240e7a2aa5c82284efaa7" },
    { label: "News", key: "7ded19433571470cadb0ee061a3e55ef" },
    { label: "Shopping & Retail", key: "fd9ce1c54de14fff8e2c2ecbeffa3ffc" },
    {
      label: "Sports, Hobbies & Leisure",
      key: "63ec6c644da84793acc6facc652ea2be"
    },
    {
      label: "Travel, Accommodation & Transportation",
      key: "9e5a288c85d64fe187626800f2f55331"
    }
  ],
  interestEnabled: true,

  // Age and  Gender Targeting
  ages: [
    { label: "18-25", key: "f358bb81742146389f46172c3342e280" },
    { label: "26-35", key: "3af4cd27f3ff4d77b162c5e908da7742" },
    { label: "36-45", key: "4db7421afb394a29aa0e5810e5f48831" },
    { label: "46-55", key: "cb04985217714a86bf73b6e9ec8b7ff6" },
    { label: "56+", key: "fdd0059f0fe24de4bc3b245c0cbc31bf" }
  ],
  ageEnabled: true,
  genders: [
    {label: "Male", key: "925caf2163974953b330893dd391dc8b"},
    {label: "Female", key: "ee19b488a59242b78902f09890b4c15d"},
  ],
  genderEnabled: true,

  // Site Targeting
  sites: [
    {
      label:
        "Business (e.g. USAToday Money, Investing Channel, Investopedia, Business Insider, WSJ, etc)",
      key: "645cedda57774ec0a94da61f21582132"
    },
    {
      label:
        "Entertainment (e.g. Hollywood Life, Bustle, Complex, Polygon, IGN, Lad Bible, People, etc)",
      key: "659a03c4dc114a1da2893a05bf41db48"
    },
    {
      label:
        "Fashion & Beauty (e.g. Byrdie, Brides, InStyle, Stylecaster, etc)",
      key: "c5ed34d8bce44e228db4282f0e549134"
    },
    {
      label:
        "Food (e.g. Eater, Food Network, All Recipes, Eating Well, Leites Culinaria, etc)",
      key: "68a968c0b0f04f8d9c2b4fc42caf7c2e"
    },
    {
      label:
        "General Consumer (e.g. USAToday, NY Post, Buzzfeed, Vox, The Washington Post, MailOnline, Time, CNN, etc)",
      key: "5078b2d400be43faa8916054826cfde7"
    },
    {
      label: "Health (e.g. Healthline, Very Well, Health, Parents, etc)",
      key: "c0915a8886bd4d6aa1ce28aaaf25569b"
    },
    {
      label:
        "Lifestyle (e.g. SheKnows, Curbed, Cheddar, Weather, TheSpruce, Fusion TV, etc)",
      key: "f9e45b35690942078726ed49d9700a93"
    },
    {
      label:
        "Sports (e.g Sportswire, SB Nation, ESPN, MLB, Sports Illustrated, Bleacher Report, etc)",
      key: "484865d6bf2148d68d3340fc57b00132"
    },
    {
      label: "Technology (e.g. The Verge, Recode, MacRumors, Mashable, , etc)",
      key: "76089c141a254b12aff05443d72111cc"
    },
    {
      label:
        "Travel (e.g. TimeOut, TripSavvy, Travel Channel, Travel and Leisure, etc)",
key: "fa03bb053d2b419680b3627645c8a251"}
  ],
  siteEnabled: true,

  // Geo Targeting
  geos: [
    { label: "US", key: "6292eb2b75a649fa9033ebe46d6c1765" },
    { label: "UK", key: "afc2161eb32046c389a4230fef51775f" },
    { label: "Canada", key: "1a9a2c8e10d642ac84bc9bdcea9bed03" },
    { label: "Australia", key: "3231bc48480f46d8b6719246c6ba83b1" },
    { label: "Global", key: "90ee18d7bf384f938807c655ce744e57" }
  ],
  geoEnabled: true,

  // ADDITIONAL INFORMATION
  additionalInformationEnabled: true,
  additionalInformationSubheader:
    "Provide click, impression trackers or other comments.",

  // PAYMENT
  stripeEnabled: true,
  // Text rendered below the payment field
  paymentLabel:
    "You will be charged from Polar Inc. on your credit card in $USD for the full budget on the start date of your campaign.",
  // Account ID field Subheader
  // Only used when stripe is not enabled
  accountIdSubheader: "",

  // TERMS AND CONDITIONS
  tacEnabled: true,
  // Enables rendering T&C as HTML
  // If true, the T&C must be defined as an HTML object in the htmlTermsAndConditions object inside TermsAndConditionsModal.jsx
  tacHTML: false,
  // Used as the terms and conditions text when tacHTML is false
  termsAndConditions:
    "This Order is governed by the IAB's Standard Terms and Conditions for Internet Advertising for Media Buys.",

  // Footer displayed below submit button
  partnerMessage: "For questions, contact direct@polar.me"
};
