import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Form,
  Header,
  Input,
  Label,
  Radio,
  TextArea,
} from "semantic-ui-react";
import { Translate } from "react-translated";
import RangeSlider from "./RangeSlider";

import { calculateImpressions } from "../utils/helpers";
import { formatNumber, getLongDate } from "../utils/formatters";

export default class CampaignDetails extends Component {
  static propTypes = {
    onFieldChange: PropTypes.func.isRequired,
    //campaignName: PropTypes.string.isRequired,
    socialPostURLs: PropTypes.string.isRequired,
    budget: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    ctaURL: PropTypes.string.isRequired,
    ctaText: PropTypes.string.isRequired,
    // To be cleaned up someday
    // startDay: PropTypes.string.isRequired,
    // startMonth: PropTypes.string.isRequired,
    // startYear: PropTypes.string.isRequired,
    // endDay: PropTypes.string.isRequired,
    // endMonth: PropTypes.string.isRequired,
    // endYear: PropTypes.string.isRequired,
    budgetSliderEnabled: PropTypes.bool.isRequired,
    budgetSliderSettings: PropTypes.object.isRequired,
    impressionLabelEnabled: PropTypes.bool.isRequired,
    CPM: PropTypes.number.isRequired,
    fixedCPMLabel: PropTypes.string.isRequired,
  };

  get dateError() {
    const { date } = this.props.errors;
    if (date) {
      return (
        <Label basic pointing="left" color="red">
          Start date must be before end date.
        </Label>
      );
    }
    return null;
  }

  onChangeWithLimit = (limit, e, { name, value }) => {
    if (value.length <= limit) {
      this.props.onFieldChange(e, { name, value });
    }
  };

  onRadioChange = (e, { name, value, cpm, duration }) => {
    const { onFieldChange } = this.props;
    onFieldChange(e, { name, value });
    onFieldChange(e, { name: "CPM", value: cpm });
    if (this.props.budgetRadioSetsDuration) {
      onFieldChange(e, { name: "range-duration", value: duration });
    }
  };

  renderDateFields() {
    const {
      dateSliderEnabled,
      dateSliderSettings,
      durationSliderSettings,
      disableDurationSlider,
      dateSubheader,
      errors,
      onFieldChange,
      startDay,
      startMonth,
      startYear,
      endDay,
      endMonth,
      endYear,
      budgetRadioSetsDuration,
      language,
      europeanDate,
    } = this.props;

    if (dateSliderEnabled) {
      const longStartDate = getLongDate(
        startDay,
        startMonth,
        startYear,
        language
      );
      const longEndDate = getLongDate(endDay, endMonth, endYear, language);

      return (
        <div>
          <Form.Field error={errors.budget}>
            <Header as="h5">
              <Translate text="Start Date" /> *
            </Header>
            {dateSubheader.length > 0 && (
              <Header.Subheader>{dateSubheader}</Header.Subheader>
            )}
            <RangeSlider
              max={dateSliderSettings.max}
              min={dateSliderSettings.min}
              start={dateSliderSettings.start}
              step={dateSliderSettings.step}
              pre_label={dateSliderSettings.labelBeforeValue}
              post_label={dateSliderSettings.labelAfterValue}
              color={dateSliderSettings.color}
              name="range-start-date"
              onUpdate={onFieldChange}
              specialLabel={longStartDate.split(",")[0]}
            />
          </Form.Field>
          {!budgetRadioSetsDuration && !disableDurationSlider && (
            <div>
              <Form.Field error={errors.budget}>
                <Header as="h5">
                  <Translate text="Duration" /> *
                </Header>
                <RangeSlider
                  max={durationSliderSettings.max}
                  min={durationSliderSettings.min}
                  start={durationSliderSettings.start}
                  step={durationSliderSettings.step}
                  pre_label={durationSliderSettings.labelBeforeValue}
                  post_label={durationSliderSettings.labelAfterValue}
                  color={durationSliderSettings.color}
                  name="range-duration"
                  onUpdate={onFieldChange}
                />
              </Form.Field>
              <p>
                <Translate text="Campaign End" />
                {longEndDate}
              </p>
            </div>
          )}
        </div>
      );
    } else {
      const startDayComponent = (
        <Form.Field error={errors.startDay}>
          <Form.Input
            className="dateField"
            name="startDay"
            value={this.props.startDay}
            onChange={this.onChangeWithLimit.bind(this, 2)}
          />
          <label>DD</label>
        </Form.Field>
      );
      const startMonthComponent = (
        <Form.Field error={errors.startMonth}>
          <Form.Input
            className="dateField"
            name="startMonth"
            value={this.props.startMonth}
            onChange={this.onChangeWithLimit.bind(this, 2)}
          />
          <label>MM</label>
        </Form.Field>
      );

      const endDayComponent = (
        <Form.Field error={errors.endDay}>
          <Form.Input
            className="dateField"
            name="endDay"
            value={this.props.endDay}
            onChange={this.onChangeWithLimit.bind(this, 2)}
          />
          <label>DD</label>
        </Form.Field>
      );
      const endMonthComponent = (
        <Form.Field error={errors.endMonth}>
          <Form.Input
            className="dateField"
            name="endMonth"
            value={this.props.endMonth}
            onChange={this.onChangeWithLimit.bind(this, 2)}
          />
          <label>MM</label>
        </Form.Field>
      );

      return (
        <div>
          <Form.Field>
            <Header as="h5">
              <Translate text="Start Date" /> * {this.dateError}
            </Header>
            <Form.Group unstackable>
              {europeanDate ? startDayComponent : startMonthComponent}
              {europeanDate ? startMonthComponent : startDayComponent}
              <Form.Field error={errors.startYear}>
                <Form.Input
                  className="yearField"
                  name="startYear"
                  value={this.props.startYear}
                  onChange={this.onChangeWithLimit.bind(this, 4)}
                />
                <label>YYYY</label>
              </Form.Field>
            </Form.Group>
          </Form.Field>
          {!budgetRadioSetsDuration && (
            <Form.Field>
              <Header as="h5">
                <Translate text="End Date" /> *
              </Header>
              <Form.Group unstackable>
                {europeanDate ? endDayComponent : endMonthComponent}
                {europeanDate ? endMonthComponent : endDayComponent}
                <Form.Field error={errors.endYear}>
                  <Form.Input
                    className="yearField"
                    name="endYear"
                    value={this.props.endYear}
                    onChange={this.onChangeWithLimit.bind(this, 4)}
                  />
                  <label>YYYY</label>
                </Form.Field>
              </Form.Group>
            </Form.Field>
          )}
        </div>
      );
    }
  }

  renderBudgetFields() {
    const {
      partnerName,
      budgetSliderEnabled,
      budgetSliderSettings,
      budgetRadioEnabled,
      budgetRadioOptions,
      promoEnabled,
      promoPercentage,
      promoEndDate,
      impressionLabelEnabled,
      onFieldChange,
      budget,
      CPM,
      fixedCPMLabel,
      VAT,
      errors,
      disableBudgetSlider,
    } = this.props;
    let impressions;
    if (partnerName === "tss") {
      impressions = calculateImpressions(Math.floor(budget / 1.15), CPM);
    } else {
      impressions = calculateImpressions(budget, CPM);
    }
    let formattedImpressions = formatNumber(impressions);
    const formattedBudget = formatNumber(budget);

    let promoLabel = null;

    if (promoEnabled) {
      const promoImpressions = calculateImpressions(
        budget,
        CPM,
        promoPercentage
      );
      const extraImpressions = formatNumber(promoImpressions - impressions);
      promoLabel = (
        <p className="promoLabel">
          <Translate
            text="Promo Label"
            data={{ extraImpressions, promoPercentage, promoEndDate }}
          />
        </p>
      );

      formattedImpressions = formatNumber(promoImpressions);
    }

    const impressionLabel =
      partnerName === "tss" ? (
        <p className="impressionLabel">
          {formatNumber(Math.floor(impressions / 2))} people will see your ad
          twice for your spend of {budgetSliderSettings.labelBeforeValue || "R"}
          {formattedBudget} (VAT inclusive).
        </p>
      ) : (
        <p className="impressionLabel">
          <Translate
            text="Impression Label"
            data={{
              formattedImpressions,
              before: budgetSliderSettings.labelBeforeValue || "$",
              formattedBudget,
            }}
          />{" "}
          {VAT && (
            <Translate
              text="VAT"
              data={{
                budgetWithVAT: formatNumber(Math.round(budget * (1 + VAT))),
              }}
            />
          )}
        </p>
      );

    const footer = (
      <div>
        {impressionLabelEnabled && budget !== "" && impressionLabel}
        {promoEnabled && promoLabel}
      </div>
    );

    if (budgetSliderEnabled) {
      return (
        <Form.Field error={errors.budget}>
          <Header as="h5">
            <Translate text="Budget" /> *
          </Header>
          {disableBudgetSlider ? (
            <br />
          ) : (
            <RangeSlider
              max={budgetSliderSettings.max}
              min={budgetSliderSettings.min}
              start={budgetSliderSettings.start}
              step={budgetSliderSettings.step}
              pre_label={budgetSliderSettings.labelBeforeValue}
              post_label={budgetSliderSettings.labelAfterValue}
              color={budgetSliderSettings.color}
              name="range-budget"
              onUpdate={onFieldChange}
              customClass="budgetHandleLabel"
            />
          )}
          {footer}
        </Form.Field>
      );
    } else if (budgetRadioEnabled) {
      return (
        <Form.Field>
          <Header as="h5">
            <Translate text="Budget" /> *
          </Header>
          {budgetRadioOptions.map((radio) => (
            <div>
              <br />
              <Radio
                label={radio.label}
                name="budget"
                value={radio.budget}
                cpm={radio.cpm}
                duration={radio.duration}
                checked={budget === radio.budget}
                onChange={this.onRadioChange}
              />
            </div>
          ))}
          {footer}
        </Form.Field>
      );
    } else {
      return (
        <Form.Field error={errors.budget}>
          <Header as="h5">
            <Translate text="Budget" /> *
          </Header>
          <Header.Subheader>{fixedCPMLabel}</Header.Subheader>
          <Input name="budget" value={budget} onChange={onFieldChange} />
          {footer}
        </Form.Field>
      );
    }
  }

  render() {
    const {
      onFieldChange,
      onCheckBoxToggle,
      errors,
      previewToolEnabled,
      previewToolURL,
      socialOwnershipCheckboxEnabled,
      socialOwnershipAccepted,
      campaignSubheader,
    } = this.props;

    return (
      <div>
        <Header className="contentHeader">
          <Translate text="Campaign" />
        </Header>
        <Header.Subheader>{campaignSubheader}</Header.Subheader>
        <Divider />
        {/* <Form.Field error={errors.campaignName}>
              <Header as="h5">Campaign Name *</Header>
              <Input
                name="campaignName"
                value={this.props.campaignName}
                onChange={onFieldChange}
              />
            </Form.Field> */}
        <Form.Field error={errors.socialPostURLs}>
          <Header as="h5">
            <Translate text="Social Post URL(s)" /> *
          </Header>
          <Header.Subheader>
            <Translate text="Social Post Subheader" />
          </Header.Subheader>
          <TextArea
            name="socialPostURLs"
            value={this.props.socialPostURLs}
            onChange={onFieldChange}
          />
          {socialOwnershipCheckboxEnabled && (
            <Form.Field
              error={errors.socialOwnership}
              label={<Translate text="Social Post Permission" />}
              value="socialOwnershipAccepted"
              key="socialOwnershipAccepted"
              control="input"
              type="checkbox"
              checked={socialOwnershipAccepted}
              onChange={onCheckBoxToggle}
            />
          )}
        </Form.Field>
        {previewToolEnabled && (
          <Form.Field>
            <Header as="h5">
              <Translate text="Ad Previews" />
            </Header>
            <Header.Subheader>
              <Translate text="Preview Before" />
              <a
                href={previewToolURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Translate text="here" />
              </a>{" "}
            </Header.Subheader>
          </Form.Field>
        )}

        <Form.Field>
          <Header as="h5">
            <Translate text="Destination Page" />
          </Header>
          <Header.Subheader>
            <Translate text="Destination Subheader" />
          </Header.Subheader>
          <Input
            name="ctaURL"
            value={this.props.ctaURL}
            onChange={onFieldChange}
          />
        </Form.Field>
        <Form.Field>
          <Header as="h5">
            <Translate text="CTA Text" />
          </Header>
          <Header.Subheader>
            <Translate text="CTA Text Subheader" />
          </Header.Subheader>
          <Input
            name="ctaText"
            value={this.props.ctaText}
            onChange={this.onChangeWithLimit.bind(this, 20)}
          />
          <label> {this.props.ctaText.length}/20</label>
        </Form.Field>
        {this.renderDateFields()}
        {this.renderBudgetFields()}
      </div>
    );
  }
}
