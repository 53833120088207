module.exports = {
  // FORM LEVEL CONFIG
  // Required for backend - Format: <RoleID>:<Role API Token>
  roleToken: "14252:967dd9fbb44a48d0aec153bd778bb774",
  // Required for backend - Marketplace unique hash
  // https://mediavoice.com/admin/distribution/marketplace/
  marketplace: "2ff296e2ac22403fbb1ca56c2fb754d6",
  currency: "usd",
  // Used as the page title
  pageTitle: "Zeus Prime Order Form",
  // Header image clickout
  imageLink: "https://polar.me/zeusprime",
  toEmails: ["DigitalTrafficking@washpost.com", "adops@washpost.com"],
  sendConfirmationEmail: true,
  confirmationEmailFrom: "The Washington Post Client Services Manager",

  // CAMPAIGN DETAILS
  // Turns on the social post ownership checkbox (NA-8879)
  socialOwnershipCheckboxEnabled: true,
  previewToolEnabled: true,
  previewToolURL:
    "https://mediavoice.com/preview-tool/simple/99e8d7c530004f16ae3b017e5d6f341b",
  // Dates
  dateSliderEnabled: true,
  dateSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day",
    color: "grey",
    start: 1,
    min: 1,
    max: 7,
    step: 1,
  },
  durationSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day total",
    color: "grey",
    start: 7,
    min: 3,
    max: 21,
    step: 1,
  },
  // Label shown below date fields
  dateSubheader: "",
  // Budget/CPM
  budgetSliderEnabled: true,
  budgetSliderSettings: {
    labelBeforeValue: "$",
    labelAfterValue: " estimated total spend",
    color: "grey",
    start: 700,
    min: 500,
    max: 3000,
    step: 100,
  },
  // Enables the estimated impressions text below the budget field
  impressionLabelEnabled: true,
  cpm: 16.5,
  // Budget field subheader
  fixedCPMLabel: "",
  // Budget Promotions (NA-8912)
  // Promo percentage should be an integer, it will be converted and applied to the impressions count
  promoPercentage: 20,
  // In order to work, start and end dates must be in MM/DD/YYYY format
  // Single digits will work fine (ie 1 for January)
  promoStartDate: "10/01/2019",
  promoEndDate: "11/15/2019",

  // TARGETING
  // All targeting options are in the format of:
  // label: Visual label in the UI
  // Key: corresponding segment or site ID in the hub admin
  // Audience Targeting
  audiences: [
    {
      label: "Arts, Entertainment, & Pop Culture",
      key: "6a1df3225c954b7894f335f71a08c0e4",
    },
    {
      label: "Banking, Finance, & Investing",
      key: "c20cf97cf8e34d6dbb0ef496df172dfe",
    },
    {
      label: "DC Metro Area Commuters",
      key: "2f23564d442346109617ca310492bc68",
    },
    { label: "Family & Lifestyle", key: "58f5cf0d1ea84e368383ab29514dbd4b" },
    {
      label: "Food, Drink, & Recipes",
      key: "59eb42af340e4d12b68cf912d3a1ffb3",
    },
    { label: "Government & Policy", key: "711acb35e0e7431cb1626c6a41c18557" },
    { label: "Health Care", key: "e5f0581b6fbd49b68651f78864760538" },
    { label: "Politics", key: "b02625cc925f45828ec7842b9ae67cec" },
    { label: "Real Estate", key: "d6d71aaa26a24cec95817bcecb983a3b" },
    {
      label: "Science, Health, & Technology",
      key: "4a48bf0bb5ad42f5b9debb800767499f",
    },
    { label: "Sports", key: "7f90af6572a44407a6cb209bfaa1a7b5" },
    { label: "Technology", key: "9e01332f294c43c7bebeda972b0ac430" },
    { label: "Travel", key: "28fc86f4443f46faac36d33cfd8d69cb" },
  ],
  audienceEnabled: true,
  // Used as the UI Header for the audience targeting section
  audienceTitle: "Content",

  // Interest Targeting
  interests: [
    { label: "Arts & Entertainment", key: "Arts & Entertainment" },
    { label: "Autos & Vehicles", key: "Autos & Vehicles" },
    { label: "Beauty & Fitness", key: "Beauty & Fitness" },
    {
      label: "Business, Finance & Industrial",
      key: "Business, Finance & Industrial",
    },
    { label: "Computers & Electronics", key: "Computers & Electronics" },
    { label: "Food, Drink & Health", key: "Food, Drink & Health" },
    { label: "Home, Garden & Real Estate", key: "Home, Garden & Real Estate" },
    { label: "Law & Government", key: "Law & Government" },
    { label: "News", key: "News" },
    { label: "Shopping & Retail", key: "Shopping & Retail" },
    { label: "Sports", key: "Sports" },
    { label: "Travel", key: "Travel" },
  ],
  interestEnabled: false,

  // Site Targeting
  sites: [
    { label: "Washington Post", key: "38a280d9ae574724baea87f3d33386c5" },
    { label: "Apple News", key: "430e443026754031a4122b51416b5f07" },
  ],
  siteEnabled: true,

  // Geo Targeting
  geos: [
    { label: "US - National", key: "8b551742b19347ef821dd725386dce71" },
    { label: "US - Washington DC", key: "e1be9f6124cc4e37ac924d0f07077445" },
  ],
  geoEnabled: true,
  // Age and  Gender Targeting
  // Values are hardcoded in constants.js and do not change per-form
  ageEnabled: false,
  genderEnabled: false,

  // ADDITIONAL INFORMATION
  additionalInformationEnabled: true,
  additionalInformationSubheader: "Provide trackers or any other instructions.",

  // PAYMENT
  stripeEnabled: true,
  // Text rendered below the payment field
  paymentLabel:
    "You will be charged from Polar Inc. on your credit card in $USD for the full budget on the start date of your campaign. This payment is non-refundable and non-cancellable upon hitting submit.",
  // Account ID field Subheader
  // Only used when stripe is not enabled
  accountIdSubheader: "",

  // TERMS AND CONDITIONS
  tacEnabled: true,
  // Enables rendering T&C as HTML
  // If true, the T&C must be defined as an HTML object in the htmlTermsAndConditions object inside TermsAndConditionsModal.jsx
  tacHTML: true,
  // Used as the terms and conditions text when tacHTML is false
  termsAndConditions: "",

  // Footer displayed below submit button
  partnerMessage: "For questions, contact ZeusPrime@washpost.com",
};
