module.exports = {
    // FORM LEVEL CONFIG
    // Required for backend - Format: <RoleID>:<Role API Token>
    roleToken: "15128:45ae08c909f6493195f077d21fd68081",
    // Required for backend - Marketplace unique hash
    // https://mediavoice.com/admin/distribution/marketplace/
    marketplace: "b752af95172e482cb1fd911cc5d19354",
    // Must be a stripe supported currency code
    // See: https://stripe.com/docs/currencies
    currency: "eur",
    // Used as the page title
    pageTitle: "Mail Online Ireland Order Form",
    // Header image clickout
    imageLink: "https://polar.me/socialplus",
    toEmails: ["adops@dmgmedia.ie", "kendall@polar.me", "Karl.Tracey@dmgmedia.ie", "Lorraine.Worth@dmgmedia.ie", "Gilmara.Rocha@dmgmedia.ie", "c.nidhuibhinn@dmgmedia.ie"],    

    // CAMPAIGN DETAILS
    previewToolEnabled: false,
    previewToolURL: "",
    // Dates
    europeanDate:true,
    dateSliderEnabled: false,
    dateSliderSettings: {
        labelBeforeValue: "",
        labelAfterValue: " day",
        color: "grey",
        start: 1,
        min: 1,
        max: 7,
        step: 1,
    },
    durationSliderSettings: {
        labelBeforeValue: "",
        labelAfterValue: " day total",
        color: "grey",
        start: 3,
        min: 3,
        max: 30,
        step: 1,
    },
    // Label shown below date fields
    dateSubheader: "",
    // Budget/CPM
    budgetSliderEnabled: true,
    budgetSliderSettings: {
        labelBeforeValue: "€",
        labelAfterValue: " estimated total spend",
        color: "grey",
        start: 2,
        min: 300,
        max: 3000,
        step: 100,
    },
    // Enables budget radio options 
    budgetRadioEnabled: false,
    // Budget Options will use budget and cpm values for calculations
    // and will submit to the backend. By default, the middle option is selected
    budgetRadioOptions: [
        {
            budget: 1000,
            cpm: 25,
            label: "$1,000 (40,000 ads, duration of 2 weeks)"
        },
        {
            budget: 2500,
            cpm: 20,
            label: "$2,500 (125,000 ads, duration of 4 weeks)"
        },
        {
            budget: 5000,
            cpm: 16.66,
            label: "$5,000 (300,000 ads, duration of 6 weeks)"
        },
    ],
    // Enables the estimated impressions text below the budget field
    impressionLabelEnabled: true,
    cpm: 6,
    // Budget field subheader
    fixedCPMLabel: "Fixed Rate CPM of €6.",


    // TARGETING
    // All targeting options are in the format of:
    // label: Visual label in the UI
    // Key: corresponding segment or site ID in the hub admin
    // Audience Targeting
    audiences: [
    ],
    audienceEnabled: true,
    // Used as the UI Header for the audience targeting section
    audienceTitle: "Content Targeting",
    audienceTargetingNotesEnabled: false,

    // Interest Targeting
    interests: [
        { label: "Arts & Entertainment", key: "Arts & Entertainment" },
        { label: "Autos & Vehicles", key: "Autos & Vehicles" },
        { label: "Beauty & Fitness", key: "Beauty & Fitness" },
        { label: "Business, Finance & Industrial", key: "Business, Finance & Industrial" },
        { label: "Computers & Electronics", key: "Computers & Electronics" },
        { label: "Food, Drink & Health", key: "Food, Drink & Health" },
        { label: "Home, Garden & Real Estate", key: "Home, Garden & Real Estate" },
        { label: "Law & Government", key: "Law & Government" },
        { label: "News", key: "News" },
        { label: "Shopping & Retail", key: "Shopping & Retail" },
        { label: "Sports", key: "Sports" },
        { label: "Travel", key: "Travel" },
    ],
    interestEnabled: false,

    // Site Targeting
    sites: [
        {label: "Mail Online", key: "2b71338d600840508e6b9282d19bbef4"},
        {label: "EVOKE", key: "fcaa1b870fbb41ca905d89a9bf57d08a"},
        {label: "Extra.ie", key: "54071f7f8b964188a04d737f0eccbc2e"},
        {label: "Rollercoaster.ie", key: "19a6cbeb7e0a4ccda9a9defb43ed2a5a"},
    ],
    siteEnabled: true,

    // Geo Targeting
    geos: [
      {label: "Ireland", key: "2a7a67f42d094ce7bfd519ab3ae6e5c9"},
        {label: "Northern Ireland", key: "95600eeab6cc453a804b4470ecca5b72"},
    ],
    geoEnabled: true,
    // Age and  Gender Targeting
    // Values are hardcoded in constants.js and do not change per-form
    ageEnabled: false,
    genderEnabled: false,
    
    // ADDITIONAL INFORMATION
    additionalInformationEnabled: true,
    additionalInformationSubheader: "Provide click, impression trackers or other comments.",


    // PAYMENT
    stripeEnabled: false,
    // Text rendered below the payment field
    paymentLabel: "",
    // Account ID field Subheader
    // Only used when stripe is not enabled
    accountIdSubheader: "",
    disablePayment: true,
    
    // TERMS AND CONDITIONS
    tacEnabled: true,
    // Enables rendering T&C as HTML
    // If true, the T&C must be defined as an HTML object in the htmlTermsAndConditions object inside TermsAndConditionsModal.jsx
    tacHTML: false,
    // Used as the terms and conditions text when tacHTML is false
    termsAndConditions: "This Order is governed by the IAB's Standard Terms and Conditions for Internet Advertising for Media Buys.",

    // Footer displayed below submit button
    partnerMessage: "",

    // NA-9627
    contentTargetingTextInputEnabled: true,
}