// Returns true if parameters produce a valid date sometime in the future,
// within the next 10 years
// Day offset is the minimum days in the future the date must be to be considered valid
export function isValidDate(day, month, year, dayOffset) {
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return false;
  }

  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth();
  const nowDay = now.getDate();

  if (year < nowYear || year > nowYear + 10) {
    return false;
  }

  if (day < 1 || day > daysInMonth[month - 1]) {
    if (!isLeapYear(day, month, year)) {
      return false;
    }
  }

  if (month < 1 || month > 12) {
    return false;
  }

  const date = new Date(year, month - 1, day);
  if (date < now) {
    return false;
  }

  // Check offset
  if (
    date.getFullYear() === nowYear &&
    date.getMonth() === nowMonth &&
    date.getDate() - nowDay < dayOffset
  ) {
    return false;
  }

  return true;
}

function isLeapYear(day, month, year) {
  if (month === 2 && day === 29) {
    return year % 4 === 0 || (year % 100 === 0 && year % 400 === 0);
  }
}

// Basic email validation regex
// Taken from: https://www.regular-expressions.info/email.html
export function isValidEmail(email) {
  const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return re.test(email);
}
