module.exports = {
  // FORM LEVEL CONFIG
  // Required for backend - Format: <RoleID>:<Role API Token>
  roleToken: "13907:6c2fa396400842619c6d1b99c2c300bb",
  // Required for backend - Marketplace unique hash
  // https://mediavoice.com/admin/distribution/marketplace/
  marketplace: "cdbe62bebfe547828ef8934c968e02fb",
  currency: "usd",
  // Used as the page title
  pageTitle: "Bloomberg Order Form",
  // Header image clickout
  imageLink: "https://polar.me/bloomberg",
  toEmails: [],

  // CAMPAIGN DETAILS
  // Turns on the social post ownership checkbox (NA-8879)
  socialOwnershipCheckboxEnabled: false,
  previewToolEnabled: false,
  previewToolURL: "",
  // Dates
  dateSliderEnabled: true,
  dateSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day",
    color: "grey",
    start: 3,
    min: 3,
    max: 7,
    step: 1
  },
  durationSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day total",
    color: "grey",
    start: 7,
    min: 3,
    max: 30,
    step: 1
  },
  // Label shown below date fields
  dateSubheader: "",
  // Budget/CPM
  budgetSliderEnabled: true,
  budgetSliderSettings: {
    labelBeforeValue: "$",
    labelAfterValue: " estimated total spend",
    color: "grey",
    start: 2000,
    min: 5000,
    max: 20000,
    step: 500
  },
  // Enables the estimated impressions text below the budget field
  impressionLabelEnabled: true,
  cpm: 38,
  // Budget field subheader
  fixedCPMLabel: "",
  // Budget Promotions (NA-8912)
  // Promo percentage should be an integer, it will be converted and applied to the impressions count

  // TARGETING
  // All targeting options are in the format of:
  // label: Visual label in the UI
  // Key: corresponding segment or site ID in the hub admin
  // Audience Targeting
  audiences: [
    { label: "Run of Site", key: "a18980595704451799e33d6277eb691f"},
    { label: "Businessweek", key: "38a81b3cb4404d849f1e55e1db64c141"},
    { label: "Markets", key: "07ec27f4410c4c249984ee0197b00251"},
    { label: "Opinion", key: "0fdfb852283e48a58d0ee6b8b7a7c6c9"},
    { label: "Politics", key: "052f85ec4474462aaf9bb29a68f9feaf"},
    { label: "Pursuits", key: "e654b0783db1405186450876b300209b"},
    { label: "Technology", key: "24513874dbb74fa79d8e591bcdfb41bf"},
  ],

  audienceEnabled: true,
  // Used as the UI Header for the audience targeting section
  audienceTitle: "Content",

  // Interest Targeting
  interests: [
    { label: "Arts & Entertainment", key: "Arts & Entertainment" },
    { label: "Autos & Vehicles", key: "Autos & Vehicles" },
    { label: "Beauty & Fitness", key: "Beauty & Fitness" },
  ],
  interestEnabled: false,

  // Site Targeting
  sites: [{ label: "Bloomberg", key: "e0986251124a419b935c9cc82d27e429" }],
  siteEnabled: false,

  // Geo Targeting
  geos: [
    { label: "US", key: "4fd7e52f7d624f0f971265556603dffb" },
    { label: "UK", key: "a110c908cf884e8294bd6203226a33a7" },
    { label: "France", key: "2d6a8d522a3a4fbf8f5f6d420fcc5005" },
    { label: "Germany", key: "c1fe89308f8242dcb14724cc660aeeff" },
    { label: "Australia", key: "3877b77be4fc434280304eccf5bc60b0" },
    { label: "Canada", key: "501fa81b2a0945f69854c86cd2767cb7" },
    {label: "Singapore", key: "0eea61a749744fb486e392c27b828108"},
    {label: "EMEA", key: "830de142166247eea08c02e9115b4da9"},
    {label: "APAC", key: "81ce6c397f0c47329941d137bcb29acb"},
    {label: "Latin America", key: "02033fd931524f95a70c8b377f852f83"},
    {label: "Global", key: "1bb7d88ed18140c4a17907b0199bf921"}

  ],
  geoEnabled: true,
  // Age and  Gender Targeting
  // Values are hardcoded in constants.js and do not change per-form
  ageEnabled: false,
  genderEnabled: false,

  // ADDITIONAL INFORMATION
  additionalInformationEnabled: true,
  additionalInformationSubheader: "Provide trackers or any other instructions.",

  // PAYMENT
  stripeEnabled: true,
  // Text rendered below the payment field
  paymentLabel:
    "You will be charged from Polar Inc. on your credit card in $USD for the full budget on the start date of your campaign.",
  // Account ID field Subheader
  // Only used when stripe is not enabled
  accountIdSubheader: "",
  // Disable the payment field altogether
  disablePayment: false,

  // TERMS AND CONDITIONS
  tacEnabled: true,
  // Enables rendering T&C as HTML
  // If true, the T&C must be defined as an HTML object in the htmlTermsAndConditions object inside TermsAndConditionsModal.jsx
  tacHTML: true,
  // Used as the terms and conditions text when tacHTML is false
  termsAndConditions: "",

  // Footer displayed below submit button
  partnerMessage: "You will receive an email to confirm your campaign and provide payment before it goes live."
};
