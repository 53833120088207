import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Message } from "semantic-ui-react";

import HeaderImage from "./HeaderImage";

export default class Success extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  get partnerName() {
    return this.props.location.pathname.split("/")[1];
  }

  get submissionHash() {
    const {search} = this.props.location;
    if (search.indexOf("PayUReference") !== -1) {
      return search.split("PayUReference=")[1];
    }
    return search.substring(1);
  }

  render() {
    return (
      <div>
        <HeaderImage partnerName={this.partnerName} />
        <Message positive>
          <Message.Header>Success!</Message.Header>
          <p>
            Thank you for your submission, your order is now in review. Receipt
            id: {this.submissionHash}.{" "}
            <Link to={`/${this.partnerName}`}>Submit Another.</Link>
          </p>
        </Message>
      </div>
    );
  }
}
