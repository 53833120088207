module.exports = {
    // FORM LEVEL CONFIG
    // Required for backend - Format: <RoleID>:<Role API Token>
    roleToken: ":",
    // Required for backend - Marketplace unique hash
    // https://mediavoice.com/admin/distribution/marketplace/
    marketplace: "",
    // Must be a stripe supported currency code
    // See: https://stripe.com/docs/currencies
    currency: "eur",
    // Used as the page title
    pageTitle: "Xtra Social Order Form",
    // Header image clickout
    imageLink: "https://polar.me/pgxs",
    toEmails: [],    

    // CAMPAIGN DETAILS
    previewToolEnabled: false,
    previewToolURL: "",
    // Dates
    dateSliderEnabled: false,
    dateSliderSettings: {
        labelBeforeValue: "",
        labelAfterValue: " day",
        color: "grey",
        start: 1,
        min: 1,
        max: 7,
        step: 1,
    },
    durationSliderSettings: {
        labelBeforeValue: "",
        labelAfterValue: " day total",
        color: "grey",
        start: 3,
        min: 3,
        max: 21,
        step: 1,
    },
    // Label shown below date fields
    dateSubheader: "",
    // Budget/CPM
    budgetSliderEnabled: true,
    budgetSliderSettings: {
        labelBeforeValue: "€",
        labelAfterValue: " estimated total spend",
        color: "grey",
        start: 2,
        min: 300,
        max: 3000,
        step: 100,
    },
    // Enables budget radio options 
    budgetRadioEnabled: false,
    // Budget Options will use budget and cpm values for calculations
    // and will submit to the backend. By default, the middle option is selected
    budgetRadioOptions: [
        {
            budget: 1000,
            cpm: 25,
            label: "$1,000 (40,000 ads, duration of 2 weeks)"
        },
        {
            budget: 2500,
            cpm: 20,
            label: "$2,500 (125,000 ads, duration of 4 weeks)"
        },
        {
            budget: 5000,
            cpm: 16.66,
            label: "$5,000 (300,000 ads, duration of 6 weeks)"
        },
    ],
    // Enables the estimated impressions text below the budget field
    impressionLabelEnabled: true,
    cpm: 10,
    // Budget field subheader
    fixedCPMLabel: "Fixed Rate CPM of $10.",


    // TARGETING
    // All targeting options are in the format of:
    // label: Visual label in the UI
    // Key: corresponding segment or site ID in the hub admin
    // Audience Targeting
    audiences: [
        {label: "Government & Policy", key: "1"},
        {label: "Politics", key: "2"},
        {label: "Family & Lifestyle", key: "3"},
        {label: "Science, Health, & Technology", key: "4"},
        {label: "Arts, Entertainment, & Pop Culture", key: "5"},
        {label: "Technology", key: "6"},
        {label: "Food, Drink, & Recipes", key: "7"},
        {label: "Banking, Finance, & Investing", key: "8"},
        {label: "Health Care", key: "9"},
        {label: "Travel", key: "10"},
        {label: "Sports", key: "11"},
    ],
    audienceEnabled: true,
    // Used as the UI Header for the audience targeting section
    audienceTitle: "Content Targeting",

    // Interest Targeting
    interests: [
        { label: "Arts & Entertainment", key: "Arts & Entertainment" },
        { label: "Autos & Vehicles", key: "Autos & Vehicles" },
        { label: "Beauty & Fitness", key: "Beauty & Fitness" },
        { label: "Business, Finance & Industrial", key: "Business, Finance & Industrial" },
        { label: "Computers & Electronics", key: "Computers & Electronics" },
        { label: "Food, Drink & Health", key: "Food, Drink & Health" },
        { label: "Home, Garden & Real Estate", key: "Home, Garden & Real Estate" },
        { label: "Law & Government", key: "Law & Government" },
        { label: "News", key: "News" },
        { label: "Shopping & Retail", key: "Shopping & Retail" },
        { label: "Sports", key: "Sports" },
        { label: "Travel", key: "Travel" },
    ],
    interestEnabled: false,

    // Site Targeting
    sites: [
        {label: "AD.nl", key: "f4320fb45f4243b1a1f2c2b123581968"},
        {label: "BNdestem", key: "08e95967146d4620ab22bcbd9983f3d8"},
        {label: "Brabants Dagblad", key: "6967068c63d14152b593a7a965a1ddad"},
        {label: "deGelderlander", key: "9e52443df170415d945e83d2d96601ab"},
        {label: "deStentor", key: "bc355fc2b07143d5ac317d2608ccaa74"},
        {label: "Het Parool", key: "bc9413351fa24f238ff9d9ef1da33a82"},
        {label: "Eindhovens Dagblad", key: "0e15764bd06742c09e8dcdf9c33422f0"},
        {label: "Indebuurt", key: "6b362b975da74ca19723c6581e8a5838"},
        {label: "PZC", key: "f96647f727af43fa9121cb07fb1b60c0"},
        {label: "Tubantia", key: "7584e6ced83f4c34a90872281b09967c"},
        {label: "Sportnieuws", key: "5c2d858305be495dbc6035bcff91a0b5"},
    ],
    siteEnabled: true,

    // Geo Targeting
    geos: [
        {label: "Local", key: "23"},
        {label: "National", key: "24"},
    ],
    geoEnabled: true,
    // Age and  Gender Targeting
    // Values are hardcoded in constants.js and do not change per-form
    ageEnabled: false,
    genderEnabled: false,
    
    // ADDITIONAL INFORMATION
    additionalInformationEnabled: true,
    additionalInformationSubheader: "Provide click, impression trackers or other comments.",


    // PAYMENT
    stripeEnabled: true,
    // Text rendered below the payment field
    paymentLabel: "",
    // Account ID field Subheader
    // Only used when stripe is not enabled
    accountIdSubheader: "",

    // TERMS AND CONDITIONS
    tacEnabled: true,
    // Enables rendering T&C as HTML
    // If true, the T&C must be defined as an HTML object in the htmlTermsAndConditions object inside TermsAndConditionsModal.jsx
    tacHTML: false,
    // Used as the terms and conditions text when tacHTML is false
    termsAndConditions: "This Order is governed by the IAB's Standard Terms and Conditions for Internet Advertising for Media Buys.",

    // Footer displayed below submit button
    partnerMessage: ""
}