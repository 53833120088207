import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import {Translate} from "react-translated";

export default class TermsAndConditionsModal extends Component {
  static propTypes = {
    partnerName: PropTypes.string.isRequired,
    termsAndConditions: PropTypes.string.isRequired,
    renderAsHTML: PropTypes.bool.isRequired
  };

  get termsAndConditions() {
    const { renderAsHTML, termsAndConditions, partnerName } = this.props;
    if (renderAsHTML && htmlTermsAndConditions[partnerName]) {
      return htmlTermsAndConditions[partnerName];
    } else {
      return termsAndConditions;
    }
  }

  render() {
    return (
      <Modal
        closeIcon
        trigger={
          //eslint-disable-next-line
          <a href="" onClick={e => e.preventDefault()}>
            <Translate text="Terms and Conditions"/>
          </a>
        }
      >
        <Modal.Header>Terms and Conditions</Modal.Header>
        <Modal.Content>{this.termsAndConditions}</Modal.Content>
      </Modal>
    );
  }
}

const htmlTermsAndConditions = {
  zeusprime: (
    <div>
      This Order is governed by the Washington Post's{" "}
      <a
        href="https://www.washingtonpost.com/wp-stat/ad/public/static/media_kit/18-1941-01-AdBookSM.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        {" "}
        ad policies{" "}
      </a>
      .
      You will be charged from Polar Inc. on your credit card in $USD for the full budget on the start date of your campaign. This payment is non-refundable and non-cancellable upon hitting submit.
    </div>
  ),
  adweek: (
    <div>
      This Order is governed by Adweek's{" "}
        <a
          href="http://www.adweek.com/wp-content/uploads/2019/09/Adweek-IO-Standard-Terms-Conditions.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          ad policies{" "}
        </a>
      .
    </div>
  ),
  tss: (
    <div>
      <p>
      TERMS & CONDITIONS OF THE SPACESTATION’S ONLINE MARKETING AGREEMENT
      <br/>These Terms are the contract:
      <br/>BETWEEN:
      <br/>(i) the party to the applicable insertion order (“IO”) (whether principal or agent)(“Advertiser”); and
      <br/>(ii) The SpaceStation is division of 24.com and Media24 (Registration No. 2006/021408/07) (“SpaceStation”)
      <br/>ABOUT: the rendering of advertisements (“the Ads”) on participating websites (each “a Website”) (“the Ad Rendering”).
      <br/>1) The T&C’s shall be read with the IO. If there is a conflict, the IO shall take precedence.
      <br/>2) Each IO shall be in the form required by SpaceStation.
      <br/>3) The IO shall specify the details of the Ads to be rendered and of the Website(s) (“the Deliverables”), the applicable commercial terms, details of any third party ad server (“3rd Party Ad Server”) and any special conditions.
      <br/>4) No IO shall bind SpaceStation unless accepted by it. SpaceStation shall endeavour to accept the IO within 1 business days of receipt. Acceptance shall either be written or by the first Ad impression delivered by SpaceStation pursuant to the IO.
      <br/>5) SpaceStation may deviate from an IO if agreed to by Advertiser or where SpaceStation is acting in a manner it reasonably considers to be in the interests of Advertiser.
      <br/>6) SpaceStation shall endeavour to provide Advertiser 10 business days notice if SpaceStation becomes aware of any material changes to the Website that may potentially materially change the target audience or significantly affect the size or placement of an Ad (“Website Modification” ).
      <br/>7) If SpaceStation gives Website Modification notice or if no Website Modification notice is given but facts exist that reasonably entitle Advertiser to such a notice, then Advertiser, as its sole remedy, may cancel the then remainder of the IO without penalty provided it gives written notice to SpaceStation within 10 days of either the notice or it becoming aware of the relevant facts.
      <br/>9) Notwithstanding Website Modification, Advertiser may not be entitled to cancel where the Ads concerned are to be rendered earlier than 10 days from the date of the acceptance of the relevant IO.
      <br/>10) Advertiser shall pay to SpaceStation the amount stated on the IO (plus VAT thereon) for the Ad Rendering within 30 days of invoice unless otherwise provided for in the IO. Such amount will be based on the campaign dates as stated on the IO and will not be pro-rata on a per impression delivered basis.
      <br/>11) Should Advertiser fail to pay any amount by its due date or otherwise breach any obligation it owes to SpaceStation, SpaceStation may (as one of its remedies) suspend the Ad Rendering.
      <br/>12) Interest at the prime interest rate charged by ABSA Bank Ltd shall accrue on all overdue amounts from the due date until date of payment.
      <br/>13) A certificate by any director of SpaceStation, whose appointment authority need not be proved, shall be prima facie proof of the amount of Advertiser’s indebtedness to SpaceStation for purposes of obtaining summary judgment or provisional sentence.
      <br/>14) In the event of SpaceStation instructing its attorneys to recover monies from Advertiser, Advertiser shall be liable for and shall pay all legal costs incurred by SpaceStation on an attorney/client scale, inclusive of collection commission.
      <br/>15) Advertiser agrees that, whilst SpaceStation uses reasonable efforts to prevent click fraud, Advertiser shall have no claim of any nature whatsoever against SpaceStation in the event of any click fraud arising in respect of the Ads.
      <br/>16) Where Advertiser is represented by an agent or is located outside of South Africa, SpaceStation may require Advertiser to confirm that agent’s representative capacity and may impose further conditions to SpaceStation’s acceptance of the IO, including advance payment.
      <br/>17) An agent, by signing an IO, agrees (in its own capacity and not as representative of its principal) to indemnify SpaceStation from any and all losses incurred as a result of Advertiser’s breach of any obligations to SpaceStation.
      <br/>18) SpaceStation shall provide Advertiser with such reports as Advertiser may reasonably require from time to time.
      <br/>19) Cancellations:
      <br/>&nbsp;&nbsp;&nbsp;&nbsp;a) Advertiser may cancel any IO, without penalty, if notice is given to SpaceStation more than 30 days prior to the rendering of the first Ad contemplated in the IO. If a shorter cancellation notice is given then Advertiser is liable to SpaceStation for the cost of those Ads scheduled to be rendered during the 30 day period following the notice. For clarity and by way of example, if Advertiser cancels the IO 15 days prior to the serving of the first impression, Advertiser will only be responsible for the first 15 days of the IO;
      <br/>20) Campaign postponements:
      <br/>&nbsp;&nbsp;&nbsp;&nbsp;a) Where it is able to accommodate postponement requests, received from the Advertiser in writing and that explicitly state postponement dates, SpaceStation shall endeavour to execute such postponement in accordance with the request received.
      <br/>&nbsp;&nbsp;&nbsp;&nbsp;b) Any postponement shall be subject to inventory availability within the new campaign date range and should insufficient inventory be available, SpaceStation shall afford the Advertiser inventory on an alternative environment, save that the Advertiser shall remain liable for the full campaign billing value as per the original IO (without the consideration of any discount or credits) irrespective of whether or not the Advertiser agrees to the alternative inventory offered by SpaceStation.
      <br/>&nbsp;&nbsp;&nbsp;&nbsp;c) Billing and payment of all fees shall be as per the original IO campaign dates and shall not be amended in respect of the postponement dates.
      <br/>&nbsp;&nbsp;&nbsp;&nbsp;d) Should the Advertiser opt to cancel the IO after requesting a postponement, albeit such cancellation is affected prior to, or within, the postponed campaign period, the cancellation shall revert back to the original campaign commencement date and any cancellation fees shall be determined as from the original campaign start date.
      <br/>21) Late material supply:
      <br/>&nbsp;&nbsp;&nbsp;&nbsp;a) Material is deemed to be supplied late by the Advertiser, when such material is delivered to SpaceStation less than 5 (five) business days prior to the rendering of the first Ad contemplated in the IO (“the Late Material”).
      <br/>&nbsp;&nbsp;&nbsp;&nbsp;b) Whilst SpaceStation shall endeavour to implement the Late Material in time to render the first Ad contemplated in the IO, SpaceStation shall not in any event whatsoever be held liable to the Advertiser or any third party should the first Ad not be rendered by the specified campaign start date as per the IO.
      <br/>&nbsp;&nbsp;&nbsp;&nbsp;c) SpaceStation shall not be held liable by the Advertiser and/or any third party for any reason whatsoever in the event that the supply of the Late Material effects SpaceStation’s ability to meet its obligations as per the IO, save that SpaceStation shall use its reasonable commercial endeavours to meet the IO delivery requirements for CPM campaigns. The Advertiser shall remain liable for the full payment of the IO, notwithstanding SpaceStation’s failure to meet its delivery obligations as a result of the Advertiser’s supply of the Late Material.
      <br/>&nbsp;&nbsp;&nbsp;&nbsp;d) notwithstanding paragraph 21c) above, SpaceStation shall, inventory permitting, use its reasonable commercial endeavours to deliver as per the original IO requirements, save that SpaceStation shall not be held liable in any circumstances whatsoever, nor shall the Advertiser be entitled to withhold any payments due to SpaceStation, should SpaceStation be unable to find suitable replacement inventory and/or placements.
      <br/>22) Either party may terminate an IO at any time if the other party is in material breach of its obligations under these T&C’s, any IO or any policy (as contemplated below) and that breach, if it can be cured, is not cured within 10 days after written notice thereof.
      <br/>23) If Advertiser breaches any policy on three separate occasions after having received notice of such breach, then SpaceStation may terminate the IO associated with such breach upon written notice even if such breach has been cured by Advertiser.
      <br/>24) SpaceStation’s sole liability to Advertise for any non-delivery of any Deliverables in respect of any Ad or IO shall be to provide Advertiser with a credit equal to the value of the under-delivered portion of the IO. Alternatively, an agreement may be reached with Advertiser to extend the campaign end date in order for the full number of impressions booked to be served.
      <br/>25) SpaceStation shall allow for a maximum 10% delivery discrepancy and where it is deemed warranted in SpaceStation’s sole discretion, SpaceStation shall deliver bonus impressions, to a maximum of 10% of the originally booked number of impressions.
      <br/>26) Save for payment obligations, neither party shall be liable for delay or default in the performance of its obligations to the other party if such delay or default is caused by conditions beyond its reasonable control not occassioned by its fault or negligence or breach of any obligation arising in terms hereof. An affected party’s obligations shall be suspended for the duration of the aforegoing circumstances. If those circumstances persist for longer than one month then the other party may cancel the relevant IO.
      <br/>27) Advertiser shall procure that all Ads shall comply with SpaceStation’s policies, criteria and specifications from time to time including as to content, technical specifications, privacy, user experience, public image, obscenity and indecency. SpaceStation may remove any Ads that SpaceStation determines as non-compliant, or as SpaceStation is instructed to remove by the publishers it represents, within their sole discretion. Such removal shall constitute an immediate cancellation of such IO with no further obligations owing by either party to each other. SpaceStation reserves the right to decline an IO within its sole discretion and there shall be no requirement on SpaceStation to submit details and the reason for such decline.
      <br/>28) Advertiser grants to SpaceStation a worldwide, royalty free license to use the Ads and any logo, trade name, trade mark, brand name, logo or domain name associated therewith for the purposes of fulfilling its obligations in respect of any IO.
      <br/>29) Advertiser hereby warrants to SpaceStation that it is, and at all relevant times shall be, the lawful owner or duly licensed user of the copyright in, and all other intellectual property rights relating to, the Ads and all the material and content provided by Advertiser to SpaceStation for the purposes
      of the IO.
      <br/>30) Each party (“the indemnifying party”) indemnifies and holds the other party (“the other party”) harmless against any claims or liability or damages arising on the part of the other party from any breach by the indemnifying party of any obligation imposed on it in any IO, these T&C’s or in law.
      <br/>31) If notwithstanding any of the aforegoing, SpaceStation is found to be liable to Advertiser for any amount or claim hereunder, such liability shall never exceed the lesser of the amount that Advertiser paid to SpaceStation in the 3 months immediately preceding the date the liability arose or the proceeds available from any insurance procured by SpaceStation in relation thereto (if any).
      <br />32) In no event shall either party be liable for any consequential, indirect, incidental, punitive, special or exemplary damages whatsoever, including without limitation, damages for loss of profits, business interruption, loss of information and the like, incurred by the other party arising out of these T&C’s or any IO.
      <br />33) The parties shall treat all information, in whatever form and howsoever recorded, that may reasonably be argued to have commercial value that a party receives from the other party as a result of this Agreement (“confidential information”), as private and confidential and safeguard it accordingly. The parties furthermore agree not to use or disclose or divulge or copy or reproduce or publish or circulate or reverse engineer and/or decompile or otherwise transfer, whether directly or indirectly, any confidential information to any other person and shall take all such steps as may be reasonably required to prevent confidential information falling into the hands of unauthorised persons.
      <br />34) Although SpaceStation affords Advertisers the ability to utilize third party delivery platforms and technologies, SpaceStation reserves the right to decline those platforms and/or technologies it deems (in its sole discretion) to have a negative impact on SpaceStation’s trading, delivery and/or
      functionality. SpaceStation shall notify Advertiser in writing of its election to decline such third party delivery platforms and technologies and any affected IO will be cancelled with immediate effect and no further obligations shall be owed by either party to the other from the time of the aforesaid cancellation.
      <br />35) Each party warrants to the other party that it has the expertise, experience and required licenses and permissions to fulfil its obligations in terms of these T&C’s and any IO.
      <br />36) Advertiser may not resell, assign or transfer any of its rights or obligations here-under.
      <br />37) All terms and provisions of these T&C’s and each IO shall be binding upon and inure to the benefit of the parties hereto and their respective permitted transferees, successors and assigns.
      <br />38) These T&C’s and the relevant IO constitute the entire agreement of the parties with respect to the subject matter and supersede all previous communications, representations, understandings, and agreements, either oral or written, between the parties with respect to the subject matter of the IO.
      <br />40) The laws of the Republic of South Africa shall apply to any IO and to these T&C’s.
      <br />41) Any claims, legal proceeding or litigation arising in connection with any IO or these T&C’s) shall be subject solely to the jurisdiction of the courts of the Republic of South Africa.
      <br />42) No modification of these T&C’s or any IO shall be binding unless in writing and signed by both parties.
      <br />43) If any provision herein is held to be unenforceable, the remaining provisions shall remain in full force and effect. All rights and remedies here-under are cumulative.
      </p>
    </div>
  ),
  example: <div>Example</div>
};
