export const STRIPE_PUBLIC_KEY = "pk_live_kpsNtpw8jSn0AEDBn1uCZdHw002Ppf57QB";
export const HUB_URL = "https://tam-poc.polarmobile.com/rest/orders/";
export const MEDIAVOICE_ADMIN_URL = "https://mediavoice.com/admin/distribution/order/";
export const SLACK_HOOK_URL = "https://hooks.slack.com/services/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";

export const TSS_ID_URL = "https://web.polarmobile.com/tam/tss_payu.php";
export const TSS_PAYMENT_URL_BASE = "https://secure.payu.co.za/rpp.do?PayUReference=";

export const VALID_ROUTES = ["sph", "tss", "xtrasocial", "direct", "aspireiq", "dms", "bloomberg", "directau", "zeusprimeplus", "mpublicite", "nextdoor", "mailmetro", "ads24", "socialplus"];

export const AUDIENCE_TYPE = "audienceValues";
export const INTEREST_TYPE = "interestValues";
export const SITE_TYPE = "siteValues";
export const GEO_TYPE = "geoValues";
export const AGE_TYPE = "ageValues";
export const GENDER_TYPE = "genderValues";
