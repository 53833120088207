import React, { Component } from "react";
import PropTypes from "prop-types";
import { Divider, Form, Header, TextArea } from "semantic-ui-react";
import { Translate } from "react-translated";

export default class AdditionalInformation extends Component {
  static propTypes = {
    onFieldChange: PropTypes.func.isRequired,
    additionalInformation: PropTypes.string.isRequired,
    subheaderText: PropTypes.string
  };

  render() {
    const { additionalInformation, subheaderText, onFieldChange } = this.props;

    return (
      <div>
        <Header className="contentHeader">
          <Translate text="Additional Information" />
        </Header>
        <Divider />
        <Form.Field>
          {subheaderText.length !== 0 && (
            <Header.Subheader>{this.props.subheaderText}</Header.Subheader>
          )}
          <TextArea
            name="additionalInformation"
            value={additionalInformation}
            onChange={onFieldChange}
          />
        </Form.Field>
      </div>
    );
  }
}
