import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";

import Orderform from "./components/Orderform";
import Success from "./components/Success";
import Page404 from "./components/Page404";

import { VALID_ROUTES, STRIPE_PUBLIC_KEY } from "./constants";

function App() {
  const locale =
    window.location.toString().indexOf("mpublicite") !== -1 ? "fr" : "en";

  return (
    <StripeProvider apiKey={STRIPE_PUBLIC_KEY} locale={locale}>
      <Elements>
        <Router>
          <Switch>
            <Route
              path={`/(zeusprime)/`}
              render={() => (window.location = "https://www.zeustechnology.com/")}
            />
            <Route
              path={`/(${VALID_ROUTES.join("|")})/success`}
              component={Success}
            />
            <Route
              path={`/(${VALID_ROUTES.join("|")})/`}
              component={Orderform}
            />
            <Route component={Page404} />
          </Switch>
        </Router>
      </Elements>
    </StripeProvider>
  );
}

export default App;
