import React, { Component } from "react";
import PropTypes from "prop-types";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import "./RangeSlider.css";

import { formatNumber } from "../utils/formatters";

function Handle({
  handle: { id, value, percent },
  pre_label,
  post_label,
  getHandleProps,
  specialLabel = null,
  customClass = "handleLabel"
}) {
  if (post_label.indexOf("day") !== -1 && value > 1) {
    post_label = post_label.replace("day", "days");
  }

  const label = pre_label + formatNumber(value) + post_label;

  return (
    <div
      className="handle"
      style={{ left: `${percent}%` }}
      {...getHandleProps(id)}
    >
      <div className={customClass}>{specialLabel ? specialLabel : label}</div>
    </div>
  );
}

function Track({ color, source, target, getTrackProps }) {
  return (
    <div
      className="track"
      style={{
        backgroundColor: color,
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`
      }}
      {...getTrackProps()}
    />
  );
}

export default class RangeSlider extends Component {
  static propTypes = {
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    start: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    pre_label: PropTypes.string.isRequired,
    post_label: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired
  };

  railStyle = {
    position: "absolute",
    width: "100%",
    height: 10,
    marginTop: 35,
    borderRadius: 5,
    backgroundColor: "#8B9CB6",
    opacity: 0.2
  };

  render() {
    const {
      max,
      min,
      step,
      start,
      color,
      onUpdate,
      pre_label,
      post_label,
      name,
      specialLabel,
      customClass
    } = this.props;

    let railStyle = Object.assign({}, this.railStyle);
    railStyle["backgroundColor"] = color;

    return (
      <Slider
        className="slider"
        domain={[min, max]}
        step={step}
        mode={2}
        values={[start]}
        onUpdate={val => {
          if (val.length >= 0) {
            onUpdate(null, { name, value: val[0] });
          }
        }}
      >
        <Rail>
          {(
            { getRailProps } // adding the rail props sets up events on the rail
          ) => <div style={railStyle} {...getRailProps()} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  pre_label={pre_label}
                  post_label={post_label}
                  getHandleProps={getHandleProps}
                  specialLabel={specialLabel}
                  customClass={customClass}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  color={color}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    );
  }
}
