export function getLongDate(day, month, year, language) {
  return `${getMonthName(month, language)} ${day}, ${year}`;
}

export function getMonthName(month, language = "en") {
  const monthNames =
    language === "fr"
      ? [
          "janv.",
          "févr.",
          "mars",
          "avril",
          "mai",
          "juin",
          "juil.",
          "août",
          "sept.",
          "oct.",
          "nov.",
          "déc."
        ]
      : [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ];

    return monthNames[month-1]
}

export function formatNumber(num) {
  return num.toLocaleString();
}
