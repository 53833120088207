import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Form,
  Header,
  Icon,
  Input,
  TextArea,
} from "semantic-ui-react";

import {
  AUDIENCE_TYPE,
  INTEREST_TYPE,
  SITE_TYPE,
  GEO_TYPE,
  AGE_TYPE,
  GENDER_TYPE,
} from "../constants";

export default class Targeting extends Component {
  static propTypes = {
    onTargetingCheckBoxToggle: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.string,
      })
    ).isRequired,
    audiences: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.string,
      })
    ).isRequired,
    geos: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.string,
      })
    ).isRequired,
    siteValues: PropTypes.object.isRequired,
    audienceValues: PropTypes.object.isRequired,
    geoValues: PropTypes.object.isRequired,
    siteEnabled: PropTypes.bool,
    audienceEnabled: PropTypes.bool,
    geoEnabled: PropTypes.bool,
  };

  static defaultProps = {
    siteEnabled: false,
    audienceEnabled: true,
    geoEnabled: false,
  };

  constructor(props) {
    super(props);

    let isAudienceNotesExpanded = {};
    for (const audience of props.audiences) {
      isAudienceNotesExpanded[audience.key] = false;
    }

    this.state = {
      siteIndex: 0,
      geoIndex: 0,
      isAudienceNotesExpanded,
    };
  }

  toggleAudienceNotesExpanded = (key) => {
    const { isAudienceNotesExpanded } = this.state;
    isAudienceNotesExpanded[key] = !isAudienceNotesExpanded[key];
    this.setState({ isAudienceNotesExpanded });
  };

  renderAudienceTargeting() {
    const {
      audiences,
      audienceValues,
      audienceTitle,
      audienceSubtitle,
      audienceDisabledByGroup,
      onAudienceValuesNotesChange,
      audienceTargetingNotesEnabled,
      onTargetingCheckBoxToggle,
      contentTargetingTextInputEnabled,
      contentTargetingTextInputValue,
      onFieldChange,
    } = this.props;

    if (contentTargetingTextInputEnabled) {
      return (
        <div>
          <Header as="h5">{audienceTitle}</Header>
          <Header.Subheader>{audienceSubtitle}</Header.Subheader>
          <Form.Field>
            <Input
              name="contentTargetingTextInputValue"
              value={contentTargetingTextInputValue}
              onChange={onFieldChange}
            />
          </Form.Field>
        </div>
      );
    }

    const { isAudienceNotesExpanded } = this.state;

    return (
      <div>
        <Header as="h5">{audienceTitle}</Header>
        <Header.Subheader>{audienceSubtitle}</Header.Subheader>
        <Form.Group grouped>
          {audiences.map((audience) => (
            <div>
              <Form.Field
                className="targetingField"
                disabled={audienceDisabledByGroup}
                label={audience.label}
                value={audience.key}
                key={audience.key}
                control="input"
                type="checkbox"
                checked={audienceValues[audience.key]}
                onChange={onTargetingCheckBoxToggle.bind(this, AUDIENCE_TYPE)}
              />
              {audienceTargetingNotesEnabled && audience.key !== "selectAll" ? (
                isAudienceNotesExpanded[audience.key] ? (
                  <div className="targetingNotesWrapper">
                    <Icon
                      fitted
                      className="targetingNotesButton"
                      name="minus"
                      onClick={this.toggleAudienceNotesExpanded.bind(
                        this,
                        audience.key
                      )}
                    />
                    <Input
                      className="targetingNotesInput"
                      onChange={onAudienceValuesNotesChange.bind(
                        this,
                        audience.key
                      )}
                    />
                  </div>
                ) : (
                  <Icon
                    className="targetingNotesButton"
                    name="plus"
                    onClick={this.toggleAudienceNotesExpanded.bind(
                      this,
                      audience.key
                    )}
                  />
                )
              ) : null}
            </div>
          ))}
        </Form.Group>
      </div>
    );
  }

  renderInterestTargeting() {
    const { interests, interestValues, onTargetingCheckBoxToggle } = this.props;

    return (
      <div>
        <Header as="h5">Interest</Header>
        <Header.Subheader></Header.Subheader>
        <Form.Group grouped>
          {interests.map((interest) => (
            <Form.Field
              label={interest.label}
              value={interest.key}
              key={interest.key}
              control="input"
              type="checkbox"
              checked={interestValues[interest.key]}
              onChange={onTargetingCheckBoxToggle.bind(this, INTEREST_TYPE)}
            />
          ))}
        </Form.Group>
      </div>
    );
  }

  renderAgeTargeting() {
    const { ages, ageValues, onTargetingCheckBoxToggle } = this.props;

    return (
      <div>
        <Header as="h5">Age</Header>
        <Form.Group grouped>
          {ages.map((age) => (
            <Form.Field
              label={age.label}
              value={age.key}
              key={age.key}
              control="input"
              type="checkbox"
              checked={ageValues[age.key]}
              onChange={onTargetingCheckBoxToggle.bind(this, AGE_TYPE)}
            />
          ))}
        </Form.Group>
      </div>
    );
  }

  renderGenderTargeting() {
    const { genders, genderValues, onTargetingCheckBoxToggle } = this.props;

    return (
      <div>
        <Header as="h5">Gender</Header>
        <Form.Group grouped>
          {genders.map((gender) => (
            <Form.Field
              label={gender.label}
              value={gender.key}
              key={gender.key}
              control="input"
              type="checkbox"
              checked={genderValues[gender.key]}
              onChange={onTargetingCheckBoxToggle.bind(this, GENDER_TYPE)}
            />
          ))}
        </Form.Group>
      </div>
    );
  }

  renderSiteTargeting() {
    const {
      sites,
      siteValues,
      siteTitle,
      siteGroupEnabled,
      onTargetingCheckBoxToggle,
    } = this.props;

    const numSelected = [];
    if (siteGroupEnabled) {
      for (const group of sites) {
        let count = 0;
        for (const site of group.sites) {
          if (site.label.indexOf("Select All") === -1) {
            if (siteValues[site.key]) {
              count++;
            }
          }
        }
        numSelected.push(count);
      }
    }

    return (
      <div>
        <Header as="h5">{siteTitle || "Websites"}</Header>
        <Form.Group grouped>
          {siteGroupEnabled ? (
            <div className="siteGroup">
              {sites.map((group, i) => (
                <span>
                  <span
                    className={
                      i === this.state.siteIndex
                        ? "activeMultiHeader multiHeader"
                        : "multiHeader"
                    }
                    onClick={() => this.setState({ siteIndex: i })}
                  >
                    {`${group.group} (${numSelected[i]} selected)`}
                  </span>
                  {i !== sites.length - 1 && "  |  "}
                </span>
              ))}
              {sites.map((group, i) => (
                <div hidden={i !== this.state.siteIndex}>
                  {group.sites.map((site) => (
                    <Form.Field
                      label={site.label}
                      value={site.key}
                      key={site.key}
                      control="input"
                      type="checkbox"
                      checked={siteValues[site.key]}
                      onChange={onTargetingCheckBoxToggle.bind(this, SITE_TYPE)}
                    />
                  ))}
                </div>
              ))}
            </div>
          ) : (
            sites.map((site) => (
              <Form.Field
                label={site.label}
                value={site.key}
                key={site.key}
                control="input"
                type="checkbox"
                checked={siteValues[site.key]}
                onChange={onTargetingCheckBoxToggle.bind(this, SITE_TYPE)}
              />
            ))
          )}
        </Form.Group>
      </div>
    );
  }

  /* <Header.Subheader>Select none or multiple.</Header.Subheader> */

  renderGeoTargeting() {
    const {
      geos,
      geoValues,
      geoGroupEnabled,
      onTargetingCheckBoxToggle,
      onGeoValuesNotesChange,
    } = this.props;
    const numSelected = [];
    if (geoGroupEnabled) {
      for (const group of geos) {
        let count = 0;
        for (const geo of group.geos) {
          if (geo.label.indexOf("Select All") === -1) {
            if (geoValues[geo.key]) {
              count++;
            }
          }
        }
        numSelected.push(count);
      }
    }

    return (
      <div>
        <Header as="h5" className={geoGroupEnabled ? "" : "geoHeaderSpaceFix"}>
          Location
        </Header>
        <Form.Group grouped>
          {geoGroupEnabled ? (
            <div className="siteGroup">
              {geos.map((group, i) => (
                <span>
                  <span
                    className={
                      i === this.state.geoIndex
                        ? "activeMultiHeader multiHeader"
                        : "multiHeader"
                    }
                    onClick={() => this.setState({ geoIndex: i })}
                  >
                    {`${group.group} (${numSelected[i]} selected)`}
                  </span>
                  {i !== geos.length - 1 && "  |  "}
                </span>
              ))}
              {geos.map((group, i) => (
                <div hidden={i !== this.state.geoIndex}>
                  {group.geos.map((geo) => (
                    <Form.Field
                      label={geo.label}
                      value={geo.key}
                      key={geo.key}
                      control="input"
                      type="checkbox"
                      checked={geoValues[geo.key]}
                      onChange={onTargetingCheckBoxToggle.bind(this, GEO_TYPE)}
                    />
                  ))}
                </div>
              ))}
            </div>
          ) : (
            geos.map((geo) => (
              <div>
                <Form.Field
                  className="targetingField"
                  label={geo.label}
                  value={geo.key}
                  key={geo.key}
                  control="input"
                  type="checkbox"
                  checked={geoValues[geo.key]}
                  onChange={onTargetingCheckBoxToggle.bind(this, GEO_TYPE)}
                />
                {this.props.partnerName === "mailmetro" &&
                  geo.label === "Local" &&
                  geoValues[geo.key] && (
                    <div className="targetingNotesWrapper">
                      <Input
                        className="targetingNotesInput"
                        placeholder="City"
                        onChange={onGeoValuesNotesChange.bind(this, geo.key)}
                      />
                    </div>
                  )}
              </div>
            ))
          )}
        </Form.Group>
      </div>
    );
  }

  render() {
    const {
      audienceEnabled,
      interestEnabled,
      ageEnabled,
      genderEnabled,
      siteEnabled,
      geoEnabled,
      targetingHeader,
    } = this.props;

    const shouldRenderTargeting =
      audienceEnabled ||
      interestEnabled ||
      siteEnabled ||
      geoEnabled ||
      ageEnabled ||
      genderEnabled;

    if (shouldRenderTargeting) {
      return (
        <div>
          <Header className="contentHeader">
            {targetingHeader || "Audience"}
          </Header>
          <Divider />
          {audienceEnabled && this.renderAudienceTargeting()}
          {interestEnabled && this.renderInterestTargeting()}
          {ageEnabled && this.renderAgeTargeting()}
          {genderEnabled && this.renderGenderTargeting()}
          {siteEnabled && this.renderSiteTargeting()}
          {geoEnabled && this.renderGeoTargeting()}
        </div>
      );
    }

    return null;
  }
}
