module.exports = {
  // FORM LEVEL CONFIG
  // Required for backend - Format: <RoleID>:<Role API Token>
  roleToken: ":",
  // Required for backend - Marketplace unique hash
  // https://mediavoice.com/admin/distribution/marketplace/
  marketplace: "",
  // Must be a stripe supported currency code
  // See: https://stripe.com/docs/currencies
  currency: "usd",
  // Used as the page title
  pageTitle: "Example Order Form",
  // Header image clickout
  imageLink: "https://polar.me/socialdisplay",
  // Emails to deliver notifications to
  toEmails: ["nicole.fung@polar.me", "al@polar.me"],
  // If true, a confirmation email is also sent to the submitter of the form (NA-9261)
  sendConfirmationEmail: true, 
  // The confirmation email contains: 'An email report will be provided at the end of the campaign from <confirmationEmailFrom>'
  confirmationEmailFrom: 'the Washington Post Client Services Manager',

  // CAMPAIGN DETAILS
  // Turns on the social post ownership checkbox (NA-8879)
  socialOwnershipCheckboxEnabled: false,
  // Show a link to the preview tool
  previewToolEnabled: false,
  previewToolURL: "",
  // Dates
  // Switches to european style date ie DD/MM/YYYY
  europeanDate:true,
  dateSliderEnabled: false,
  dateSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day",
    color: "grey",
    start: 1,
    min: 1,
    max: 7,
    step: 1
  },
  durationSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day total",
    color: "grey",
    start: 3,
    min: 3,
    max: 21,
    step: 1
  },
  // Label shown below date fields
  dateSubheader: "",
  // Budget/CPM
  budgetSliderEnabled: false,
  budgetSliderSettings: {
    labelBeforeValue: "$",
    labelAfterValue: " daily",
    color: "grey",
    start: 0,
    min: 0,
    max: 10,
    step: 1
  },
  // Enables budget radio options
  budgetRadioEnabled: false,
  // Budget Options will use budget and cpm values for calculations
  // and will submit to the backend. By default, the middle option is selected
  budgetRadioOptions: [
    {
      budget: 1000,
      cpm: 25,
      duration: 7,
      label: "$1,000 (40,000 ads, duration of 2 weeks)"
    },
    {
      budget: 2500,
      cpm: 20,
      label: "$2,500 (125,000 ads, duration of 4 weeks)"
    },
    {
      budget: 5000,
      cpm: 16.66,
      label: "$5,000 (300,000 ads, duration of 6 weeks)"
    }
  ],
  // Disables the duration input field. Intended to be used with budget radio input
  // If this is enabled, a "duration" field with a value in days must be added to each budgetRadioOption
  budgetRadioSetsDuration: false,
  // Enables the estimated impressions text below the budget field
  impressionLabelEnabled: false,
  cpm: 10,
  // Budget field subheader
  fixedCPMLabel: "Fixed Rate CPM of $10.",
  // Budget Promotions (NA-8912)
  // Promo percentage should be an integer, it will be converted and applied to the impressions count
  promoPercentage: 20,
  // In order to work, start and end dates must be in MM/DD/YYYY format
  // Single digits will work fine (ie 1 for January)
  promoStartDate: "9/29/2019",
  promoEndDate: "10/15/2019",

  // Campaign subheader
  campaignSubheader: "Served to Mobile Web and App",

  // TARGETING
  // Defaults to "Audience"
  targetingHeader: "Targeting",

  // All targeting options are in the format of:
  // label: Visual label in the UI
  // Key: corresponding segment or site ID in the hub admin
  
  // Audience Targeting
  audiences: [{ label: "value", key: "1" }],
  audienceEnabled: true,
  // Used as the UI Header for the audience targeting section
  audienceTitle: "Content",
  // Shown below the above title
  audienceSubtitle: "subtitle",
  // Toggles the audience notes feature (See NA-9415)
  audienceTargetingNotesEnabled: true,

  // Interest Targeting
  interests: [
    { label: "Arts & Entertainment", key: "Arts & Entertainment" },
    { label: "Autos & Vehicles", key: "Autos & Vehicles" },
    { label: "Beauty & Fitness", key: "Beauty & Fitness" },
    {
      label: "Business, Finance & Industrial",
      key: "Business, Finance & Industrial"
    },
    { label: "Computers & Electronics", key: "Computers & Electronics" },
    { label: "Food, Drink & Health", key: "Food, Drink & Health" },
    { label: "Home, Garden & Real Estate", key: "Home, Garden & Real Estate" },
    { label: "Law & Government", key: "Law & Government" },
    { label: "News", key: "News" },
    { label: "Shopping & Retail", key: "Shopping & Retail" },
    { label: "Sports", key: "Sports" },
    { label: "Travel", key: "Travel" }
  ],
  interestEnabled: false,

  // Age and  Gender Targeting
  // Values are hardcoded in constants.js and do not change per-form
  ageEnabled: false,
  genderEnabled: false,

  // Site Targeting
  // Example of targeting "groups"
  sites: [
    {
      group: "Regional",
      // Groups can disable audience targeting (NA-9101)
      disablesAudience: true,
      sites: [{ label: "value", key: "2" }]
    },
    { group: "Global", sites: [{ label: "value2", key: "99" }] }
  ],
  siteEnabled: false,
  // Enable "group" targeting display
  siteGroupEnabled: true,
  // Used as the title for this section in the UI
  siteTitle: "Content",

  // Geo Targeting
  geos: [{ label: "value", key: "3" }],
  geoEnabled: false,
  // Groups also work for geo
  geoGroupEnabled: false,

  // ADDITIONAL INFORMATION
  additionalInformationEnabled: true,
  additionalInformationSubheader:
    "Provide click, impression trackers or other comments.",

  // PAYMENT
  stripeEnabled: true,
  // Text rendered below the payment field
  paymentLabel: "",
  // Account ID field Subheader
  // Only used when stripe is not enabled
  accountIdSubheader: "",
  // Disable the payment field altogether
  disablePayment: false,

  // TERMS AND CONDITIONS
  tacEnabled: false,
  // Enables rendering T&C as HTML
  // If true, the T&C must be defined as an HTML object in the htmlTermsAndConditions object inside TermsAndConditionsModal.jsx
  tacHTML: false,
  // Used as the terms and conditions text when tacHTML is false
  termsAndConditions:
    "This Order is governed by the IAB's Standard Terms and Conditions for Internet Advertising for Media Buys.",

  // Footer displayed below submit button
  partnerMessage: ""
};
