module.exports = {
  // FORM LEVEL CONFIG
  // Required for backend - Format: <RoleID>:<Role API Token>
  roleToken: "13628:3f3b97ae3d804da29a3eca37131dc593",
  // Required for backend - Marketplace unique hash
  // https://mediavoice.com/admin/distribution/marketplace/
  marketplace: "368364c4550446debe99387ad3230b6e",
  // Must be a stripe supported currency code
  // See: https://stripe.com/docs/currencies
  currency: "zar",
  VAT: 0.15,
  // Used as the page title
  pageTitle: "Ads24 Order Form",
  // Header image clickout
  imageLink: "https://polar.me/tss",
  toEmails: ["bookings@24.com", "janice@spacemail.co.za", "nicole@polar.me", "matthew.claire@polar.me"],

  // CAMPAIGN DETAILS
  previewToolEnabled: false,
  previewToolURL: "",
  // Dates
  dateSliderEnabled: true,
  dateSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day",
    color: "grey",
    start: 2,
    min: 2,
    max: 7,
    step: 1
  },
  durationSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day total",
    color: "grey",
    start: 3,
    min: 3,
    max: 30,
    step: 1
  },
  // Label shown below date fields
  dateSubheader: "",
  // Budget/CPM
  budgetSliderEnabled: true,
  budgetSliderSettings: {
    labelBeforeValue: "R",
    labelAfterValue: " Total Spend",
    color: "grey",
    start: 10000,
    min: 5000,
    max: 100000,
    step: 5000
  },
  // Enables budget radio options
  budgetRadioEnabled: false,
  // Budget Options will use budget and cpm values for calculations
  // and will submit to the backend. By default, the middle option is selected
  budgetRadioOptions: [
    {
      budget: 1000,
      cpm: 25,
      label: "$1,000 (40,000 ads, duration of 2 weeks)"
    },
    {
      budget: 2500,
      cpm: 20,
      label: "$2,500 (125,000 ads, duration of 4 weeks)"
    },
    {
      budget: 5000,
      cpm: 16.66,
      label: "$5,000 (300,000 ads, duration of 6 weeks)"
    }
  ],
  // Enables the estimated impressions text below the budget field
  impressionLabelEnabled: true,
  cpm: 100,
  // Budget field subheader
  fixedCPMLabel: "Fixed Rate CPM of R100(ZAR).",

  // Campaign subheader
  campaignSubheader: "Served to Mobile Web and App",

  // TARGETING
  // Defaults to "Audience"
  targetingHeader: "Targeting",
  // All targeting options are in the format of:
  // label: Visual label in the UI
  // Key: corresponding segment or site ID in the hub admin
  // Audience Targeting
  audiences: [
    { label: "News/Current Affairs", key: "b936879dcd8547ca9245dad38b2442ae" },
    { label: "Finance/Business", key: "561e6bd0eb7441bbb53af6391dc60731" },
    { label: "Sport", key: "57693439a2de438dba520811de8a85c6" },
    { label: "Entertainment", key: "8c47e2ed6e024f0abd5064cc56b16638" },
    { label: "Motoring", key: "fa00ab5d7632497d974cd18ba16760a9" },
    { label: "Travel", key: "a34432726ea54f8885a87740863137e0" },
    { label: "Fashion & Beauty", key: "453008f0a1eb41c880103bc400c4784e" },
    { label: "Health", key: "2cef553164c24ae898b98318e4f90d8a" },
    { label: "Food", key: "dcdef40c22f448e5bb50aa80ff23dbca" },
    { label: "Parent", key: "1ffb6705e62a4ff6aa17cbcc40a81301" }
  ],
  audienceEnabled: true,
  // Used as the UI Header for the audience targeting section
  audienceTitle: "Audience",

  // Interest Targeting
  interests: [
    { label: "Arts & Entertainment", key: "Arts & Entertainment" },
    { label: "Autos & Vehicles", key: "Autos & Vehicles" },
    { label: "Beauty & Fitness", key: "Beauty & Fitness" },
    { label: "Business, Finance & Industrial", key: "Business, Finance & Industrial"},
    { label: "Computers & Electronics", key: "Computers & Electronics" },
    { label: "Food, Drink & Health", key: "Food, Drink & Health" },
    { label: "Home, Garden & Real Estate", key: "Home, Garden & Real Estate" },
    { label: "Law & Government", key: "Law & Government" },
    { label: "News", key: "News" },
    { label: "Shopping & Retail", key: "Shopping & Retail" },
    { label: "Sports", key: "Sports" },
    { label: "Travel", key: "Travel" }
  ],
  interestEnabled: false,

  // Site Targeting
  sites: [
    { label: "Netwerk24", key: "ae098470f120411fabd2b5b704ceb192" },
    { label: "Daily Sun", key: "4a1c029c04d948dbbde49655522b007b" },
    { label: "City Press", key: "4a9098e3fcc54c9dad1871588b55c4e8" },
    { label: "SON", key: "54abc8ee9c784fe6be732a2a1a0c896d" }
  ],
  siteEnabled: true,
  // Used as the title for this section in the UI
  siteTitle: "Content",

  // Geo Targeting
  geos: [
    {
      label: "National (South Africa)",
      key: "7c74d8744d2141c29ba92be2b9384fe9"
    },
    { label: "Johannesburg", key: "e7c74bf3930f482d9aeaa3de4479ca2c" },
    { label: "Cape Town", key: "d6efad52f5b2492b9648c24809146c2a" },
    { label: "Pretoria", key: "6781efaf52294c209ddc3404db8bc9e3" },
    { label: "Durban", key: "ef815573aecc42fc82a5a0f085f45e58" },
    { label: "Bloemfontein", key: "df760f6df0714fb89cacfd02b30b8211" },
    { label: "Port Elizabeth", key: "2134021b93294b888030c17b6dbef06b" },
    { label: "George", key: "e59ffcb83ec946f3b66dda981ee7766c" }
  ],
  geoEnabled: true,
  // Age and  Gender Targeting
  // Values are hardcoded in constants.js and do not change per-form
  ageEnabled: false,
  genderEnabled: false,

  // ADDITIONAL INFORMATION
  additionalInformationEnabled: true,
  additionalInformationSubheader:
    "Provide click, impression trackers or other comments.",

  // PAYMENT
  stripeEnabled: false,
  // Text rendered below the payment field
  paymentLabel:
    "You will be charged from Polar Inc. on your credit card in South African Rand for the full budget on the start date of your campaign.",
  // Account ID field Subheader
  // Only used when stripe is not enabled
  accountIdSubheader: "",
  // Disable the payment field altogether
  disablePayment: true,

  // TERMS AND CONDITIONS
  tacEnabled: true,
  // Enables rendering T&C as HTML
  // If true, the T&C must be defined as an HTML object in the htmlTermsAndConditions object inside TermsAndConditionsModal.jsx
  tacHTML: true,
  // Used as the terms and conditions text when tacHTML is false
  termsAndConditions:
    "This Order is governed by the IAB's Standard Terms and Conditions for Internet Advertising for Media Buys.",

  // Footer displayed below submit button
  partnerMessage: "Your campaign will not begin until you remit payment on the next page after submitting an order. You will receive an email to confirm your campaign details. For questions, email bookings@24.com"
}