export function calculateImpressions(budget, cpm, promoPercentage = 0) {
  const promoMultiplyer = promoPercentage / 100 + 1;

  const rawReach = parseInt((parseInt(budget) / cpm) * 1000 * promoMultiplyer);
  const decimal = Math.pow(10, rawReach.toString().length - 3);
  const roundedReach = (rawReach / decimal).toFixed() * decimal;
  return roundedReach;
}

// NA-8912
// Returns true if there is a budget promo enabled
// ie if the current date is between the specified promo
// start and end date
export function getPromoEnabled(start, end) {
  if (!start || !end) {
    return false;
  }

  const splitStart = start.split("/");
  const splitEnd = end.split("/");

  const startDate = new Date(splitStart[2], splitStart[0] - 1, splitStart[1]);
  const endDate = new Date(
    splitEnd[2],
    splitEnd[0] - 1,
    splitEnd[1],
    23,
    59,
    59,
    59
  );
  const now = new Date();

  return startDate <= now && now <= endDate;
}
