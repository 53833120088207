import React, { Component } from "react";
import PropTypes from "prop-types";
import { Divider, Form, Header, Input } from "semantic-ui-react";
import { Translate } from "react-translated";

export default class PersonalInformation extends Component {
  static propTypes = {
    onFieldChange: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  render() {
    const { firstName, lastName, email, onFieldChange, errors } = this.props;

    return (
      <div>
        <Header>
          <Translate text="Contact" />
        </Header>
        <Divider />
        <Header as="h5">
          <Translate text="Name" /> *
        </Header>
        <Form.Group widths="equal">
          <Form.Field error={errors.firstName}>
            <Input
              className="inputField"
              fluid
              name="firstName"
              value={firstName}
              onChange={onFieldChange}
            />
            <label>
              <Translate text="First" />
            </label>
          </Form.Field>
          <Form.Field error={errors.lastName}>
            <Input
              fluid
              name="lastName"
              value={lastName}
              onChange={onFieldChange}
            />
            <label>
              <Translate text="Last" />
            </label>
          </Form.Field>
        </Form.Group>
        <Form.Field error={errors.email}>
          <Header as="h5">
            <Translate text="Email" /> *
          </Header>
          <Input fluid name="email" value={email} onChange={onFieldChange} />
          {errors.email && (
            <label>
              <Translate text="Email Error" />
            </label>
          )}
        </Form.Field>
        <Form.Field error={errors.advertiserName}>
          <Header as="h5">
            {this.props.partnerName === "mailmetro" ? (
              "Company (Advertiser)"
            ) : (
              <Translate text="Company" />
            )}
            *
          </Header>
          <Input
            name="advertiserName"
            value={this.props.advertiserName}
            onChange={onFieldChange}
          />
        </Form.Field>
      </div>
    );
  }
}
