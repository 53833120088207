import axios from "axios";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Provider, Translate } from "react-translated";
import translation from "../translation";
import { Divider, Form, Header, Input, Segment } from "semantic-ui-react";
import { CardElement, injectStripe } from "react-stripe-elements";

import AdditionalInformation from "./AdditionalInformation";
import CampaignDetails from "./CampaignDetails";
import HeaderImage from "./HeaderImage";
import PersonalInformation from "./PersonalInformation";
import Targeting from "./Targeting";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import "./Orderform.css";

import { isValidDate, isValidEmail } from "../utils/validators";
import { calculateImpressions, getPromoEnabled } from "../utils/helpers";
import { getMonthName } from "../utils/formatters";
import {
  HUB_URL,
  MEDIAVOICE_ADMIN_URL,
  SLACK_HOOK_URL,
  SITE_TYPE,
  GEO_TYPE,
  TSS_ID_URL,
  TSS_PAYMENT_URL_BASE,
} from "../constants";

const NUMBER_FIELDS = {
  startDay: true,
  startMonth: true,
  startYear: true,
  endDay: true,
  endMonth: true,
  endYear: true,
  budget: true,
};

class Orderform extends Component {
  constructor(props) {
    super(props);
    this.partnerName = this.props.location.pathname.split("/")[1];

    // Import Form Field data based on partnerName
    this.config = require(`../formData/${this.partnerName}/fieldData`);

    const {
      pageTitle = "Polar Order Form",
      sites = [],
      siteGroupEnabled,
      audiences = [],
      geos = [],
      geoGroupEnabled,
      interests = [],
      ages = [],
      genders = [],
      ageEnabled,
      genderEnabled,
      budgetSliderEnabled,
      budgetSliderSettings,
      cpm,
      dateSliderEnabled,
      dateSliderSettings,
      durationSliderSettings,
      budgetRadioEnabled,
      budgetRadioOptions,
      budgetRadioSetsDuration,
      promoStartDate,
      promoEndDate,
      stripeEnabled,
    } = this.config;

    // Set page title
    document.title = pageTitle;

    // Initialize targeting checkbox data
    let audienceValues = {};
    let audienceValuesNotes = {};
    audienceValues.selectAll = true;
    for (const audience of audiences) {
      audienceValues[audience.key] = true;
      audienceValuesNotes[audience.key] = "";
    }
    let interestValues = {};
    interestValues.selectAll = true;
    for (const interest of interests) {
      interestValues[interest.key] = true;
    }
    let siteValues = {};
    if (siteGroupEnabled) {
      for (let i = 0; i < sites.length; i++) {
        siteValues[`selectAllSite-${i}`] = true;
      }
    } else {
      siteValues.selectAll = true;
    }
    for (const site of sites) {
      if (siteGroupEnabled) {
        for (const innerSite of site.sites) {
          siteValues[innerSite.key] = true;
        }
      } else {
        siteValues[site.key] = true;
      }
    }
    let geoValues = {};

    if (geoGroupEnabled) {
      for (let i = 0; i < geos.length; i++) {
        geoValues[`selectAllGeo-${i}`] = true;
      }
    } else {
      geoValues.selectAll = true;
    }
    for (const geo of geos) {
      if (geoGroupEnabled) {
        for (const innerGeo of geo.geos) {
          geoValues[innerGeo.key] = true;
        }
      } else {
        geoValues[geo.key] = true;
      }
    }
    let ageValues = {};
    ageValues.selectAll = true;
    if (ageEnabled) {
      for (const age of ages) {
        ageValues[age.key] = true;
      }
    }
    let genderValues = {};
    genderValues.selectAll = true;
    if (genderEnabled) {
      for (const gender of genders) {
        genderValues[gender.key] = true;
      }
    }

    // Budget Radio (when in use) defaults to middle option
    const radioMid = budgetRadioEnabled
      ? budgetRadioOptions[Math.floor(budgetRadioOptions.length / 2)]
      : {};

    // Initialize starting dates for cases where Sliders or Radios are enabled
    const start = new Date();
    start.setDate(start.getDate() + dateSliderSettings.start);
    let initialDuration = 1;
    if (budgetRadioEnabled && budgetRadioSetsDuration) {
      if (radioMid.duration) {
        initialDuration = radioMid.duration;
      }
    } else if (dateSliderEnabled) {
      initialDuration = durationSliderSettings.start;
    }
    const end = new Date(start);
    end.setDate(start.getDate() + initialDuration - 1);

    // Initialize starting budget and CPM values for cases where Sliders or Radios are enabled
    const budget = budgetSliderEnabled
      ? budgetSliderSettings.start
      : budgetRadioEnabled
      ? radioMid.budget
      : "";
    const CPM = budgetRadioEnabled ? radioMid.cpm : cpm !== 0 ? cpm : 10;

    // Setup Budget Promos (NA-8912)
    this.promoEnabled = getPromoEnabled(promoStartDate, promoEndDate);

    this.state = {
      // Personal Information
      firstName: "",
      lastName: "",
      email: "",
      // Campaign Details
      advertiserName: "",
      //campaignName: '',
      socialPostURLs: "",
      budget,
      ctaURL: "",
      ctaText: this.language === "fr" ? "Voyez Plus" : "Learn More",
      startDay: dateSliderEnabled ? start.getDate() : "",
      startMonth: dateSliderEnabled ? start.getMonth() + 1 : "",
      startYear: dateSliderEnabled ? start.getFullYear() : "",
      endDay: dateSliderEnabled ? end.getDate() : "",
      endMonth: dateSliderEnabled ? end.getMonth() + 1 : "",
      endYear: dateSliderEnabled ? end.getFullYear() : "",
      duration:
        dateSliderEnabled && !budgetRadioSetsDuration
          ? durationSliderSettings.start
          : initialDuration,
      // Site/Context Targeting
      audienceValues,
      audienceValuesNotes,
      interestValues,
      siteValues,
      geoValues,
      ageValues,
      genderValues,
      contentTargetingTextInputValue: "",
      geoValuesNotes: "",
      // Terms and Condition
      tacAccepted: false,
      socialOwnershipAccepted: false,
      // Additional Information
      additionalInformation: "",
      // Payment Information
      token: null,
      accountId: "",
      CPM,
      budgetFormVisible: stripeEnabled,
      // Errors
      errors: {},
      errorMessage: "",
      // Form state,
      loading: false,
      success: false,
      successID: null,
    };
  }

  get audienceDisabledByGroup() {
    const { sites, siteGroupEnabled, geos, geoGroupEnabled } = this.config;

    if (siteGroupEnabled) {
      for (const siteGroup of sites) {
        if (siteGroup.disablesAudience) {
          for (const site of siteGroup.sites) {
            const { key } = site;
            if (this.state.siteValues[key]) {
              return true;
            }
          }
        }
      }
    }
    if (geoGroupEnabled) {
      for (const geoGroup of geos) {
        if (geoGroup.disablesAudience) {
          for (const geo of geoGroup.geos) {
            const { key } = geo;
            if (this.state.geoValues[key]) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  get fullName() {
    const { firstName, lastName } = this.state;
    return `${firstName} ${lastName}`;
  }

  get language() {
    if (this.partnerName === "mpublicite") {
      return "fr";
    }
    return "en";
  }

  get selectAllLabel() {
    if (this.language === "fr") {
      return "Tout sélectionner";
    }
    return "Select All";
  }

  get submitError() {
    let { errorMessage } = this.state;
    if (errorMessage) {
      if (errorMessage === "POST Error") {
        errorMessage = (
          <div>
            <Translate
              text="Submission Error"
              renderMap={{
                rendera: () => (
                  <a href="mailto:support@polar.me">support@polar.me</a>
                ),
              }}
            />
          </div>
        );
      }

      return { content: errorMessage, pointing: "left", size: "large" };
    }
    return null;
  }

  // Form submission getters/formatters
  get formattedStartDate() {
    return `${this.state.startYear}-${this.state.startMonth}-${this.state.startDay} 0:00:00`;
  }

  get formattedEndDate() {
    return `${this.state.endYear}-${this.state.endMonth}-${this.state.endDay} 23:59:59`;
  }

  getFormNotes = (token) => {
    const {
      firstName,
      lastName,
      email,
      advertiserName,
      socialPostURLs,
      ctaURL,
      ctaText,
      additionalInformation,
      budget,
    } = this.state;
    const {
      currency,
      audiences = [],
      interests = [],
      sites = [],
      geos = [],
      ages = [],
      genders = [],
      toEmails = [],
      audienceEnabled,
      audienceTargetingNotesEnabled,
      interestEnabled,
      siteEnabled,
      geoEnabled,
      ageEnabled,
      genderEnabled,
      VAT,
      sendConfirmationEmail,
      confirmationEmailFrom,
      contentTargetingTextInputEnabled,
    } = this.config;
    let { promoPercentage } = this.config;
    const {
      CPM,
      audienceValues,
      audienceValuesNotes,
      interestValues,
      siteValues,
      geoValues,
      ageValues,
      genderValues,
    } = this.state;
    if (!this.promoEnabled) {
      promoPercentage = 0;
    }
    let impressions, totalAmount;
    if (this.partnerName === "tss") {
      impressions = calculateImpressions(
        Math.round(budget / 1.15),
        CPM,
        promoPercentage
      );
      totalAmount = budget;
    } else {
      impressions = calculateImpressions(budget, CPM, promoPercentage);
      totalAmount = VAT ? budget * (1 + VAT) : budget;
    }

    const audienceTargeting = [];
    if (audienceEnabled) {
      const addAll = this.audienceDisabledByGroup;
      for (const audience of audiences) {
        if (audienceValues[audience.key] || addAll) {
          audienceTargeting.push(audience.label);
        }
      }
    }
    const audienceTargetingNotes = [];
    if (audienceTargetingNotesEnabled && !contentTargetingTextInputEnabled) {
      for (const audience of audiences) {
        const { key } = audience;
        if (audienceValuesNotes[key] && audienceValues[key]) {
          audienceTargetingNotes.push(
            `'${audience.label}': '${audienceValuesNotes[key]}'`
          );
        }
      }
    }
    const interestTargeting = [];
    if (interestEnabled) {
      for (const interest of interests) {
        if (interestValues[interest.key]) {
          interestTargeting.push(interest.label);
        }
      }
    }
    const siteTargeting = [];
    if (siteEnabled) {
      for (const site of sites) {
        if (siteValues[site.key]) {
          siteTargeting.push(site.label);
        }
      }
    }
    const geoTargeting = [];
    if (geoEnabled) {
      for (const geo of geos) {
        if (geoValues[geo.key]) {
          geoTargeting.push(geo.label);
        }
      }
    }
    const ageTargeting = [];
    if (ageEnabled) {
      for (const age of ages) {
        if (ageValues[age.key]) {
          ageTargeting.push(age.label);
        }
      }
    }
    const genderTargeting = [];
    if (genderEnabled) {
      for (const gender of genders) {
        if (genderValues[gender.key]) {
          genderTargeting.push(gender.label);
        }
      }
    }

    const extraNotes = {};
    if (contentTargetingTextInputEnabled) {
      extraNotes.content = this.state.contentTargetingTextInputValue;
    }
    if (this.state.geoValuesNotes) {
      extraNotes.geoNotes = this.state.geoValuesNotes;
    }

    if (this.partnerName === "mailmetro") {
      toEmails.push(this.state.email);
    }

    const data = {
      token,
      firstName,
      lastName,
      email,
      advertiserName,
      socialPostURLs,
      ctaURL,
      ctaText,
      currency,
      CPM,
      VAT,
      totalAmount,
      impressions,
      additionalInformation,
      audienceTargeting,
      audienceTargetingNotes,
      interestTargeting,
      siteTargeting,
      geoTargeting,
      ageTargeting,
      genderTargeting,
      toEmails,
      extraNotes,
    };

    if (sendConfirmationEmail) {
      data.userEmail = this.state.email;
      data.userEmailFrom = confirmationEmailFrom;
    }

    return data;
  };

  get formTargets() {
    const {
      audienceValues,
      interestValues,
      siteValues,
      geoValues,
      ageValues,
      genderValues,
    } = this.state;
    const {
      audienceEnabled,
      interestEnabled,
      siteEnabled,
      geoEnabled,
      ageEnabled,
      genderEnabled,
      contentTargetingTextInputEnabled,
    } = this.config;

    const audienceTargeting = [];
    if (audienceEnabled && !contentTargetingTextInputEnabled) {
      for (const key in audienceValues) {
        if (key.indexOf("selectAll") === -1 && audienceValues[key]) {
          audienceTargeting.push(key);
        }
      }
    }

    if (interestEnabled) {
      for (const key in interestValues) {
        if (key.indexOf("selectAll") === -1 && interestValues[key]) {
          audienceTargeting.push(key);
        }
      }
    }

    const siteTargeting = [];
    if (siteEnabled) {
      for (const key in siteValues) {
        if (key.indexOf("selectAll") === -1 && siteValues[key]) {
          siteTargeting.push(key);
        }
      }
    }

    // To be re-added when proper geo targeting is setup
    // const geoTargeting = [];
    // for (const key in geoValues) {
    //     if (geoValues[key]) {
    //         geoTargeting.push(key);
    //     }
    // }
    if (geoEnabled) {
      for (const key in geoValues) {
        if (key.indexOf("selectAll") === -1 && geoValues[key]) {
          audienceTargeting.push(key);
        }
      }
    }

    if (ageEnabled) {
      for (const key in ageValues) {
        if (key.indexOf("selectAll") === -1 && ageValues[key]) {
          audienceTargeting.push(key);
        }
      }
    }

    if (genderEnabled) {
      for (const key in genderValues) {
        if (key.indexOf("selectAll") === -1 && genderValues[key]) {
          audienceTargeting.push(key);
        }
      }
    }

    return {
      site: siteTargeting,
      segment: audienceTargeting,
      // "geo-country": geoTargeting
    };
  }

  onFieldChange = (e, { name, value }) => {
    if (name === "range-start-date") {
      const start = new Date();
      start.setDate(start.getDate() + value);
      const end = new Date(start);
      end.setDate(end.getDate() + this.state.duration - 1);
      this.setState({
        startDay: start.getDate(),
        startMonth: start.getMonth() + 1,
        startYear: start.getFullYear(),
        endDay: end.getDate(),
        endMonth: end.getMonth() + 1,
        endYear: end.getFullYear(),
      });
    } else if (name === "range-duration") {
      const { startDay, startMonth, startYear } = this.state;
      const end = new Date(startMonth + "/" + startDay + "/" + startYear);
      end.setDate(end.getDate() + value - 1);
      this.setState({
        duration: value,
        endDay: end.getDate(),
        endMonth: end.getMonth() + 1,
        endYear: end.getFullYear(),
      });
    } else {
      if (name === "range-budget") {
        name = "budget";
      }

      if (!NUMBER_FIELDS[name] || /^\d*$/.test(value)) {
        const { errors } = this.state;
        errors[name] = false;
        this.setState({ [name]: value, errors });
      }
    }
  };

  onAudienceValuesNotesChange = (key, e, { value }) => {
    const { audienceValuesNotes } = this.state;
    audienceValuesNotes[key] = value;
    this.setState(audienceValuesNotes);
  };

  onGeoValuesNotesChange = (key, e, { value }) => {
    this.setState({ geoValuesNotes: value });
  };

  onTargetingCheckBoxToggle = (checkBoxType, e) => {
    const { value } = e.target;
    const stateObj = this.state[checkBoxType];
    stateObj[value] = !stateObj[value];

    // NA-9718 Mailmetro special case for UK
    if (this.partnerName === "mailmetro") {
      if (value === "4472082c07ae47b99a62b2e6a2bc5696") {
        for (const key in stateObj) {
          stateObj[key] = stateObj["4472082c07ae47b99a62b2e6a2bc5696"];
        }
      } else if (stateObj[value] === false) {
        stateObj["4472082c07ae47b99a62b2e6a2bc5696"] = false;
      } else {
        let allTrue = true;
        for (const key in stateObj) {
          if (
            key !== "4472082c07ae47b99a62b2e6a2bc5696" &&
            key !== "selectAll" &&
            !stateObj[key]
          ) {
            allTrue = false;
            break;
          }
        }
        if (allTrue) {
          stateObj["4472082c07ae47b99a62b2e6a2bc5696"] = true;
        }
      }
    }

    if (value.indexOf("selectAll") !== -1) {
      if (this.config.siteGroupEnabled && checkBoxType === SITE_TYPE) {
        const siteIndex = parseInt(value.split("-")[1]);
        for (const site of this.config.sites[siteIndex].sites) {
          const { key } = site;
          stateObj[key] = stateObj[value];
        }
      } else if (this.config.geoGroupEnabled && checkBoxType === GEO_TYPE) {
        const geoIndex = parseInt(value.split("-")[1]);
        for (const geo of this.config.geos[geoIndex].geos) {
          const { key } = geo;
          stateObj[key] = stateObj[value];
        }
      } else {
        for (const key in stateObj) {
          if (key !== "selectAll") {
            stateObj[key] = stateObj.selectAll;
          }
        }
      }
    } else if (stateObj[value] === false) {
      if (this.config.siteGroupEnabled && checkBoxType === SITE_TYPE) {
        let siteIndex;
        for (let i = 0; i < this.config.sites.length; i++) {
          for (const site of this.config.sites[i].sites) {
            if (site.key === value) {
              siteIndex = i;
              break;
            }
          }
          if (siteIndex) {
            break;
          }
        }
        stateObj[`selectAllSite-${siteIndex}`] = false;
      } else if (this.config.geoGroupEnabled && checkBoxType === GEO_TYPE) {
        let geoIndex;
        for (let i = 0; i < this.config.geos.length; i++) {
          for (const geo of this.config.geos[i].geos) {
            if (geo.key === value) {
              geoIndex = i;
              break;
            }
          }
          if (geoIndex) {
            break;
          }
        }
        stateObj[`selectAllGeo-${geoIndex}`] = false;
      } else {
        stateObj.selectAll = false;
      }
    }
    this.setState({ [checkBoxType]: stateObj });
  };

  onCheckBoxToggle = (e) => {
    const type = e.target.value;
    this.setState({
      [type]: !this.state[type],
    });
  };

  // Validates all required form fields
  // Returns an "errors" object if any are found, null otherwise
  validateFields = () => {
    const {
      tacEnabled,
      stripeEnabled,
      accountIdEnabled,
      disablePayment,
      dateSliderEnabled,
      socialOwnershipCheckboxEnabled,
    } = this.config;
    let { errors, errorMessage } = this.state;
    // At this point will either have no errors, or 1 from stripe
    let numErrors = errors.stripe ? 1 : 0;

    // Validate dates
    // Ensure dates are valid
    if (!dateSliderEnabled) {
      const { startDay, startMonth, startYear } = this.state;
      if (!isValidDate(startDay, startMonth, startYear, 1)) {
        errors.startDay = true;
        errors.startMonth = true;
        errors.startYear = true;
        let d = new Date();
        d.setDate(d.getDate() + 1);
        errorMessage = `Please set a start date of ${
          d.getMonth() + 1
        }/${d.getDate()}/${d.getFullYear()} or later.`;
        numErrors++;
      }
      const { endDay, endMonth, endYear } = this.state;
      if (!isValidDate(endDay, endMonth, endYear, 2)) {
        errors.endDay = true;
        errors.endMonth = true;
        errors.endYear = true;
        let d = new Date();
        d.setDate(d.getDate() + 2);
        if (numErrors !== 0) {
          errorMessage =
            errorMessage.replace(".", "") +
            ` and an end date of ${
              d.getMonth() + 1
            }/${d.getDate()}/${d.getFullYear()} or later.`;
        } else {
          numErrors++;
          errorMessage = `Please set an end date of ${
            d.getMonth() + 1
          }/${d.getDate()}/${d.getFullYear()} or later.`;
        }
      }

      if (numErrors === 0) {
        // Ensure start date < end date
        const startDate = new Date(startYear, startMonth - 1, startDay);
        const endDate = new Date(endYear, endMonth - 1, endDay);
        if (endDate < startDate) {
          errors.date = true;
          errorMessage = "Campaign start date must be after end date.";
          numErrors++;
        }
      }
    }

    // Ensure basic required fields are filled out
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "advertiserName",
      //"campaignName",
      "socialPostURLs",
      "budget",
    ];
    for (const field of requiredFields) {
      if (!this.state[field] || this.state[field].length === 0) {
        errors[field] = true;
        errorMessage = "Please fill out all required fields.";
        numErrors++;
      }
    }

    // Ensure email is valid
    if (this.state.email && !isValidEmail(this.state.email)) {
      errors.email = true;
      errorMessage = "Please enter a valid email.";
      numErrors++;
    }

    // Ensure T&C Accepted
    if (tacEnabled && !this.state.tacAccepted) {
      errors.termsAndConditions = true;
      errorMessage = "You must accept the terms and conditions";
      numErrors++;
    }

    // Ensure Social Post Ownership
    if (socialOwnershipCheckboxEnabled && !this.state.socialOwnershipAccepted) {
      errors.socialOwnership = true;
      errorMessage =
        "Please confirm that you have permission to use the social post(s) in the 'Social Post URL' checkbox.";
      numErrors++;
    }

    // Ensure Account ID entered if enabled, or both enabled and there is a stripe error
    if (!disablePayment && (!stripeEnabled || accountIdEnabled)) {
      if (stripeEnabled && accountIdEnabled) {
        if (errors.stripe) {
          if (this.state.accountId.length === 0) {
            errors.accountId = true;
            errorMessage =
              "Please enter credit card information or an account ID";
          } else {
            errors.stripe = false;
            numErrors--;
          }
        }
      } else if (this.state.accountId.length === 0) {
        errors.accountId = true;
        errorMessage = "Please enter an account ID";
        numErrors++;
      }
    }

    if (numErrors > 1) {
      errorMessage =
        "Please correct the errors highlighted in red before submitting.";
    }

    if (numErrors !== 0 && this.language === "fr") {
      errorMessage =
        "Veuillez corriger les erreurs surlignées en rouge avant de soumettre.";
    }

    if (numErrors === 0) {
      errorMessage = "";
      this.setState({ errors, errorMessage }, this.submitForm);
    } else {
      this.setState({ errors, errorMessage, loading: false });
    }
  };

  // Makes a request for Stripe payment token
  validateStripe = () => {
    const errors = {};
    let errorMessage = "";

    if (this.config.stripeEnabled) {
      const stripeTokenPromise = this.props.stripe.createToken({
        name: this.fullName,
      });
      stripeTokenPromise.then((response) => {
        if (response.error) {
          errors.stripe = true;
          errorMessage = response.error.message;
          this.setState({ errors, errorMessage }, this.validateFields);
        } else {
          this.setState(
            { errors, errorMessage, token: response.token.id },
            this.validateFields
          );
        }
      });
    } else {
      this.setState({ errors, errorMessage }, this.validateFields);
    }
  };

  // Make request to hub with form data
  submitForm = () => {
    const { firstName, lastName, advertiserName, token, budget } = this.state;

    const { marketplace } = this.config;

    const postData = {
      booking_type: "managed",
      parameters: {
        name: `TAM ${firstName} ${lastName} - ${advertiserName}`,
        start_date: this.formattedStartDate,
        end_date: this.formattedEndDate,
        notes: this.getFormNotes(token),
        budget_value: budget * 100,
        budget_uom: "cents",
        budget_type: "lifetime",
      },
      line_items: [
        {
          marketplace,
          parameters: {
            bid: 500,
            bid_uom: "cpm",
            creatives: [],
            targets: this.formTargets,
          },
        },
      ],
    };

    // For Testing
    // console.log(postData);
    // return;

    let reference;
    if (this.partnerName === "tss") {
      const budget = this.state.budget * 100;
      const params = {
        budget: budget,
        first: this.state.firstName,
        last: this.state.lastName,
        email: this.state.email,
        advertiser: this.state.advertiserName,
        month: getMonthName(this.state.startMonth),
        year: this.state.startYear,
      };
      axios.get(TSS_ID_URL, { params }).then(({ data }) => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(data, "text/xml");
        reference = xml.getElementsByTagName("payUReference")[0].childNodes[0]
          .nodeValue;
        postData.parameters.notes.payuReferenceId = reference;
        this.submitOrder(postData);
      });
    } else {
      this.submitOrder(postData);
    }
  };

  submitOrder = (postData) => {
    const postConfig = {
      headers: {
        Authorization: `RoleToken ${this.config.roleToken}`,
        "Content-Type": "application/json",
      },
    };
    const reference = postData.parameters.notes.payuReferenceId;
    postData.parameters.notes = JSON.stringify(postData.parameters.notes);

    axios
      .post(HUB_URL, postData, postConfig)
      .then(({ data }) => {
        this.onSubmitSuccess(data.unique_hash, reference);
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorMessage: "POST Error",
        });
      });
  };

  onSubmitSuccess = (hash, reference) => {
    // Post notifaction to slack
    const postData = {
      text: `A new order has been submitted by ${this.partnerName}: ${MEDIAVOICE_ADMIN_URL}${hash}`,
    };
    const config = {
      headers: {
        // Required to successfully post to slack hook
        // See: https://stackoverflow.com/questions/45752537/slack-incoming-webhook-request-header-field-content-type-is-not-allowed-by-acce
        "Content-type": "application/x-www-form-urlencoded",
      },
    };
    axios.post(SLACK_HOOK_URL, postData, config);

    if (this.partnerName === "tss" && reference) {
      window.location.href = `${TSS_PAYMENT_URL_BASE}${reference}`;
    } else {
      this.setState({
        success: true,
        successID: hash,
        loading: false,
      });
    }
  };

  // On Submit:
  // 1. Validate Fields, then
  // 2. Validate Stripe information, then
  // 3. Post to backend
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, this.validateStripe);
  };

  renderAdditionalField() {
    const { additionalInformationSubheader } = this.config;

    return (
      <AdditionalInformation
        onFieldChange={this.onFieldChange}
        additionalInformation={this.state.additionalInformation}
        subheaderText={additionalInformationSubheader}
      />
    );
  }

  renderPaymentField() {
    const {
      stripeEnabled,
      accountIdEnabled = false,
      paymentLabel: label = "",
      accoundIdSubheadr: subheader = "",
      disablePayment,
    } = this.config;
    if (disablePayment) {
      return null;
    }
    const { budgetFormVisible } = this.state;

    const header =
      stripeEnabled && accountIdEnabled ? (
        <Header className="contentHeader">
          <span
            className={!budgetFormVisible ? "notEnabled" : ""}
            onClick={() => this.setState({ budgetFormVisible: true })}
          >
            <Translate text="Payment" />
          </span>
          &nbsp;&nbsp;/&nbsp;&nbsp;
          <span
            className={budgetFormVisible ? "notEnabled" : ""}
            onClick={() => this.setState({ budgetFormVisible: false })}
          >
            <Translate text="Invoice" />
          </span>
        </Header>
      ) : (
        <Header className="contentHeader">
          <Translate text="Payment" />
        </Header>
      );

    const paymentComponent = budgetFormVisible ? (
      <div>
        <Header as="h5">
          <Translate text="Card" />
        </Header>
        <Segment className="paymentSegment">
          <CardElement />
        </Segment>
      </div>
    ) : (
      <Form.Field error={this.state.errors.accountId}>
        <Header as="h5">
          <Translate text="ID" />
        </Header>
        {subheader.length > 0 && (
          <Header.Subheader>{subheader}</Header.Subheader>
        )}
        <Input
          fluid
          name="accountId"
          className="accountIdInput"
          value={this.state.accountId}
          onChange={this.onFieldChange}
        />
        {/* The call to the stripe API will fail if a CardElement is not rendered, so we include this and hide it */}
        <CardElement className="accountIdWorkaround" />
      </Form.Field>
    );

    return (
      <div>
        {header}
        <Divider />
        {paymentComponent}
        {label.length > 0 && <label>{label}</label>}
        <Divider hidden />
      </div>
    );
  }

  renderTermsAndConditions() {
    const { termsAndConditions, tacHTML } = this.config;
    const modal = (
      <TermsAndConditionsModal
        partnerName={this.partnerName}
        termsAndConditions={termsAndConditions}
        renderAsHTML={tacHTML}
      />
    );

    return (
      <div>
        <Form.Field
          error={this.state.errors.termsAndConditions}
          label={
            <span>
              <Translate text="I accept the" /> {modal}
            </span>
          }
          value={"tacAccepted"}
          key="termsAndConditions"
          control="input"
          type="checkbox"
          checked={this.state.tacAccepted}
          onChange={this.onCheckBoxToggle}
        />
        <Divider hidden />
      </div>
    );
  }

  renderPartnerMessage() {
    const htmlPartnerMessages = {
      example: (
        <div>some text, this must be one outer element wrapped in ()</div>
      ),
    };

    if (htmlPartnerMessages[this.partnerName]) {
      return htmlPartnerMessages[this.partnerName];
    }

    const { partnerMessage } = this.config;
    if (partnerMessage.length > 0) {
      return <label>{partnerMessage}</label>;
    }

    return null;
  }

  render() {
    let {
      audiences = [],
      interests = [],
      sites = [],
      geos = [],
      ages = [],
      genders = [],
      imageLink,
    } = this.config;

    const {
      currency,
      audienceEnabled,
      audienceTargetingNotesEnabled,
      interestEnabled,
      siteEnabled,
      siteGroupEnabled,
      geoEnabled,
      geoGroupEnabled,
      ageEnabled,
      genderEnabled,
      audienceTitle,
      audienceSubtitle,
      siteTitle,
      campaignSubheader,
      tacEnabled,
      additionalInformationEnabled,
      socialOwnershipCheckboxEnabled,
      previewToolEnabled,
      previewToolURL,
      budgetSliderEnabled,
      budgetSliderSettings,
      disableDurationSlider,
      disableBudgetSlider,
      budgetRadioEnabled,
      budgetRadioOptions,
      budgetRadioSetsDuration,
      impressionLabelEnabled,
      fixedCPMLabel,
      dateSliderEnabled,
      dateSliderSettings,
      durationSliderSettings,
      dateSubheader,
      europeanDate,
      promoPercentage,
      promoEndDate,
      partnerMessage = "",
      targetingHeader,
      VAT,
      contentTargetingTextInputEnabled,
    } = this.config;

    audiences =
      audiences.length > 1
        ? [{ key: "selectAll", label: this.selectAllLabel }, ...audiences]
        : audiences;
    interests =
      interests.length > 1
        ? [{ key: "selectAll", label: this.selectAllLabel }, ...interests]
        : interests;
    if (siteGroupEnabled) {
      for (let i = 0; i < sites.length; i++) {
        const group = sites[i];
        group.sites =
          group.sites.length > 1 && group.sites[0].label !== this.selectAllLabel
            ? [
                { key: `selectAllSite-${i}`, label: this.selectAllLabel },
                ...group.sites,
              ]
            : group.sites;
      }
    } else {
      sites =
        sites.length > 1
          ? [{ key: "selectAll", label: this.selectAllLabel }, ...sites]
          : sites;
    }
    if (geoGroupEnabled) {
      for (let i = 0; i < geos.length; i++) {
        const group = geos[i];
        group.geos =
          group.geos.length > 1 && group.geos[0].label !== this.selectAllLabel
            ? [
                { key: `selectAllGeo-${i}`, label: this.selectAllLabel },
                ...group.geos,
              ]
            : group.geos;
      }
    } else {
      geos =
        geos.length > 1 && this.partnerName !== "mailmetro"
          ? [{ key: "selectAll", label: this.selectAllLabel }, ...geos]
          : geos;
    }
    ages = ages || [];
    ages =
      ages.length > 1
        ? [{ key: "selectAll", label: this.selectAllLabel }, ...ages]
        : ages;
    genders = genders || [];
    genders =
      genders.length > 1
        ? [{ key: "selectAll", label: this.selectAllLabel }, ...genders]
        : genders;

    const { success, successID } = this.state;

    const form = (
      <Provider language={this.language} translation={translation}>
        <Form>
          <HeaderImage partnerName={this.partnerName} imageLink={imageLink} />
          <PersonalInformation
            onFieldChange={this.onFieldChange}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            errors={this.state.errors}
            partnerName={this.partnerName}
          />
          <CampaignDetails
            partnerName={this.partnerName}
            onFieldChange={this.onFieldChange}
            onCheckBoxToggle={this.onCheckBoxToggle}
            advertiserName={this.state.advertiserName}
            //campaignName={this.state.campaignName}
            socialPostURLs={this.state.socialPostURLs}
            budget={this.state.budget}
            ctaURL={this.state.ctaURL}
            ctaText={this.state.ctaText}
            startDay={this.state.startDay}
            startMonth={this.state.startMonth}
            startYear={this.state.startYear}
            endDay={this.state.endDay}
            endMonth={this.state.endMonth}
            endYear={this.state.endYear}
            budgetRadioEnabled={budgetRadioEnabled}
            budgetRadioOptions={budgetRadioOptions}
            budgetRadioSetsDuration={budgetRadioSetsDuration}
            budgetSliderEnabled={budgetSliderEnabled}
            budgetSliderSettings={budgetSliderSettings}
            impressionLabelEnabled={impressionLabelEnabled}
            CPM={this.state.CPM}
            VAT={VAT}
            fixedCPMLabel={fixedCPMLabel}
            currency={currency}
            dateSliderEnabled={dateSliderEnabled}
            dateSliderSettings={dateSliderSettings}
            durationSliderSettings={durationSliderSettings}
            dateSubheader={dateSubheader}
            europeanDate={europeanDate}
            previewToolEnabled={previewToolEnabled}
            previewToolURL={previewToolURL}
            socialOwnershipCheckboxEnabled={socialOwnershipCheckboxEnabled}
            socialOwnershipAccepted={this.state.socialOwnershipAccepted}
            promoPercentage={promoPercentage}
            promoEnabled={this.promoEnabled}
            promoEndDate={promoEndDate}
            errors={this.state.errors}
            language={this.language}
            campaignSubheader={campaignSubheader}
            disableDurationSlider={disableDurationSlider}
            disableBudgetSlider={disableBudgetSlider}
          />
          <Divider hidden />
          <Targeting
            onTargetingCheckBoxToggle={this.onTargetingCheckBoxToggle}
            onAudienceValuesNotesChange={this.onAudienceValuesNotesChange}
            audiences={audiences}
            audienceTitle={audienceTitle}
            audienceSubtitle={audienceSubtitle}
            audienceDisabledByGroup={this.audienceDisabledByGroup}
            interests={interests}
            sites={sites}
            geos={geos}
            ages={ages}
            genders={genders}
            audienceValues={this.state.audienceValues}
            audienceValuesNotes={this.state.audienceValuesNotes}
            audienceTargetingNotesEnabled={audienceTargetingNotesEnabled}
            interestValues={this.state.interestValues}
            siteValues={this.state.siteValues}
            geoValues={this.state.geoValues}
            ageValues={this.state.ageValues}
            genderValues={this.state.genderValues}
            audienceEnabled={audienceEnabled}
            interestEnabled={interestEnabled}
            siteEnabled={siteEnabled}
            siteGroupEnabled={siteGroupEnabled}
            siteTitle={siteTitle}
            geoEnabled={geoEnabled}
            geoGroupEnabled={geoGroupEnabled}
            ageEnabled={ageEnabled}
            genderEnabled={genderEnabled}
            targetingHeader={targetingHeader}
            error={this.state.errors.targeting}
            contentTargetingTextInputEnabled={contentTargetingTextInputEnabled}
            contentTargetingTextInputValue={
              this.state.contentTargetingTextInputValue
            }
            onFieldChange={this.onFieldChange}
            partnerName={this.partnerName}
            geoValuesNotes={this.state.geoValuesNotes}
            onGeoValuesNotesChange={this.onGeoValuesNotesChange}
          />
          <Divider hidden />
          {additionalInformationEnabled && this.renderAdditionalField()}
          {this.renderPaymentField()}
          {tacEnabled && this.renderTermsAndConditions()}
          <Form.Button
            content={<Translate text="Submit" />}
            onClick={this.onSubmit}
            error={this.submitError}
            loading={this.state.loading}
          />
          {this.renderPartnerMessage()}
          <div className="footer">
            <a href="https://privacy.polar.me/">Privacy Policy</a>
          </div>
          <Divider hidden />
        </Form>
      </Provider>
    );

    const redirect = (
      <Redirect
        push
        to={{
          pathname: `${this.partnerName}/success`,
          search: `?${this.state.successID}`,
        }}
      />
    );

    return success && successID ? redirect : form;
  }
}

export default injectStripe(Orderform);
