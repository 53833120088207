import React, { Component } from "react";
import { Container } from "semantic-ui-react";

export default class Page404 extends Component {
  render() {
    return (
      <Container textAlign="center">
        <h1>404 - Page Not Found</h1>
        <p>
          Please <a href="mailto:support@polar.me">contact support</a> for more
          information.
        </p>
      </Container>
    );
  }
}
