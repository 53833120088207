module.exports = {
    // FORM LEVEL CONFIG
    // Required for backend - Format: <RoleID>:<Role API Token>
    roleToken: "13626:f394672ebece43d688ffce0c3d1406a7",
    // Required for backend - Marketplace unique hash
    // https://mediavoice.com/admin/distribution/marketplace/
    marketplace: "2ff296e2ac22403fbb1ca56c2fb754d6",
    currency: "usd",
    // Used as the page title
    pageTitle: "Zeus Prime Plus Order Form",
    // Header image clickout
    imageLink: "https://polar.me/zeusprime",
    toEmails: [],    
    sendConfirmationEmail: true, 
    confirmationEmailFrom: 'the Washington Post Client Services Manager',

    // CAMPAIGN DETAILS
    // Turns on the social post ownership checkbox (NA-8879)
    socialOwnershipCheckboxEnabled: true,
    previewToolEnabled: true,
    previewToolURL: "https://mediavoice.com/preview-tool/simple/99e8d7c530004f16ae3b017e5d6f341b",
    // Dates
    dateSliderEnabled: false,
    dateSliderSettings: {
        labelBeforeValue: "",
        labelAfterValue: " day",
        color: "grey",
        start: 1,
        min: 1,
        max: 7,
        step: 1,
    },
    durationSliderSettings: {
        labelBeforeValue: "",
        labelAfterValue: " day total",
        color: "grey",
        start: 7,
        min: 3,
        max: 21,
        step: 1,
    },
    // Label shown below date fields
    dateSubheader: "",
    // Budget/CPM
    budgetSliderEnabled: true,
    budgetSliderSettings: {
        labelBeforeValue: "$",
        labelAfterValue: " estimated total spend",
        color: "grey",
        start: 5000,
        min: 2500,
        max: 25000,
        step: 2500,
    },
    // Enables the estimated impressions text below the budget field
    impressionLabelEnabled: true,
    cpm: 10,
    // Budget field subheader
    fixedCPMLabel: "",
    // Budget Promotions (NA-8912)
    // Promo percentage should be an integer, it will be converted and applied to the impressions count
    // promoPercentage: ,
    // In order to work, start and end dates must be in MM/DD/YYYY format
    // Single digits will work fine (ie 1 for January)
    // promoStartDate: "",
    // promoEndDate: "",


    // TARGETING
    // All targeting options are in the format of:
    // label: Visual label in the UI
    // Key: corresponding segment or site ID in the hub admin
    // Audience Targeting
    audiences: [
        {label: "Arts, Entertainment, & Pop Culture", key: "6a1df3225c954b7894f335f71a08c0e4"},
        {label: "Banking, Finance, & Investing", key: "c20cf97cf8e34d6dbb0ef496df172dfe"},
        {label: "DC Metro Area Commuters", key: "2f23564d442346109617ca310492bc68"},
        {label: "Family & Lifestyle", key: "58f5cf0d1ea84e368383ab29514dbd4b"},
        {label: "Food, Drink, & Recipes", key: "59eb42af340e4d12b68cf912d3a1ffb3"},
        {label: "Government & Policy", key: "711acb35e0e7431cb1626c6a41c18557"},
        {label: "Health Care", key: "e5f0581b6fbd49b68651f78864760538"},
        {label: "Politics", key: "b02625cc925f45828ec7842b9ae67cec"},
        {label: "Real Estate", key: "d6d71aaa26a24cec95817bcecb983a3b"},
        {label: "Science, Health, & Technology", key: "4a48bf0bb5ad42f5b9debb800767499f"},
        {label: "Sports", key: "7f90af6572a44407a6cb209bfaa1a7b5"},
        {label: "Technology", key: "9e01332f294c43c7bebeda972b0ac430"},
        {label: "Travel", key: "28fc86f4443f46faac36d33cfd8d69cb"},
    ],
    audienceEnabled: true,
    // Used as the UI Header for the audience targeting section
    audienceTitle: "Content",
    audienceSubtitle: "Selecting any Secondary DMA will remove any content targeting to satisfy your booked impressions count.",

    // Interest Targeting
    interests: [
        { label: "Arts & Entertainment", key: "Arts & Entertainment" },
        { label: "Autos & Vehicles", key: "Autos & Vehicles" },
    ],
    interestEnabled: false,

    // Site Targeting
    sites: [
        {label: "Washington Post", key: "38a280d9ae574724baea87f3d33386c5"},
    ],
    siteEnabled: true,

    // Geo Targeting
    geos: [
        {group: "Primary DMAs", geos: [
            {label: "US - National", key: "8b551742b19347ef821dd725386dce71"},
            {label: "US - Washington DC", key: "e1be9f6124cc4e37ac924d0f07077445"},
        ]},

        {group: "Secondary DMAs", disablesAudience: true, geos: [
            {label: "Atlanta GA", key: "99ad6834d398440b847e797339589289"},
            {label: "Baltimore MD", key: "f48ab6e0a29546fc869515f18ed1b177"},
            {label: "Boston MA, Manchester NH", key: "51993ddca1f746ba81e7f8da422a7a60"},
            {label: "Chicago IL", key: "af71356b05794cf8956d06a25b12f7fe"},
            {label: "Dallas - Ft. Worth TX", key: "83341205e5ad4c7498813c88122c8c29"},
            {label: "Denver CO", key: "eb3f486fa6354738a73dd660106e6ec9"},
            {label: "Detroit MI", key: "314bb735225341b381cb040b3d681de5"},
            {label: "Houston TX", key: "7e4ec70cec9c4956abead67180342864"},
            {label: "Los Angeles CA", key: "b7ec427fdc1146e5be3e2d82828f0352"},
            {label: "Minneapolis - St. Paul MN", key: "19567f1337ea4249b797f7159d7cfead"},
            {label: "New York, NY", key: "591d137a715f46a2a1ee7e47dd4e99f5"},
            {label: "Orlando - Daytona Beach - Melbourne FL", key: "c55da2c1ca974fda90dcec5afa682068"},
            {label: "Philadelphia PA", key: "34a75c0ae0fb48be8ef51bb7a1c19cc2"},
            {label: "Phoenix AZ", key: "20d3c78448da4af883c6642d1e1ba919"},
            {label: "Portland OR", key: "dab2d8b732a04aceb7da41f4432510c5"},
            {label: "Raleigh - Durham (Fayetteville) NC", key: "4c015078334445efa973986394f02149"},
            {label: "San Diego CA", key: "f95492fe8bae4d3ca531a6bd1b8db032"},
            {label: "San Francisco - Oakland - San Jose CA", key: "66116ca6d98c4403a2f3ca362d25e17c"},
            {label: "Seattle - Tacoma WA", key: "c286431c343d402db3b21e32679c4d21"},
            {label: "Tampa - St. Petersburg (Sarasota) FL", key: "28f49db83aa2403da8d6a43e42626c59"},
        ]},
    ],
    geoEnabled: true,
    geoGroupEnabled: true,

    // Age and  Gender Targeting
    // Values are hardcoded in constants.js and do not change per-form
    ageEnabled: false,
    genderEnabled: false,
    
    // ADDITIONAL INFORMATION
    additionalInformationEnabled: true,
    additionalInformationSubheader: "Provide trackers or any other instructions.",


    // PAYMENT
    stripeEnabled: false,
    // Text rendered below the payment field
    paymentLabel: "",
    // Account ID field Subheader
    // Only used when stripe is not enabled
    accountIdSubheader: "",

    // TERMS AND CONDITIONS
    tacEnabled: true,
    // Enables rendering T&C as HTML
    // If true, the T&C must be defined as an HTML object in the htmlTermsAndConditions object inside TermsAndConditionsModal.jsx
    tacHTML: true,
    // Used as the terms and conditions text when tacHTML is false
    termsAndConditions: "",

    // Footer displayed below submit button
    partnerMessage: "For questions, contact ZeusPrime@washpost.com"
}

