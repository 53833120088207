module.exports = {
  // FORM LEVEL CONFIG
  // Required for backend - Format: <RoleID>:<Role API Token>
  roleToken: "13874:708d29001bb647c2802a20afe801c257",
  // Required for backend - Marketplace unique hash
  // https://mediavoice.com/admin/distribution/marketplace/
  marketplace: "ddc537a9e4f0494d97e1b79bf9dfc964",
  currency: "usd",
  // Used as the page title
  pageTitle: "DMS Order Form",
  // Header image clickout
  imageLink: "https://polar.me/dms",
  toEmails: [],

  // CAMPAIGN DETAILS
  // Turns on the social post ownership checkbox (NA-8879)
  socialOwnershipCheckboxEnabled: true,
  previewToolEnabled: false,
  previewToolURL:
    "https://mediavoice.com/preview-tool/simple/99e8d7c530004f16ae3b017e5d6f341b",
  // Dates
  dateSliderEnabled: false,
  dateSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day",
    color: "grey",
    start: 1,
    min: 1,
    max: 7,
    step: 1
  },
  durationSliderSettings: {
    labelBeforeValue: "",
    labelAfterValue: " day total",
    color: "grey",
    start: 3,
    min: 3,
    max: 60,
    step: 3
  },
  // Label shown below date fields
  dateSubheader: "",
  // Budget/CPM
  budgetSliderEnabled: true,
  budgetSliderSettings: {
    labelBeforeValue: "$",
    labelAfterValue: " estimated total spend",
    color: "grey",
    start: 500,
    min: 500,
    max: 25000,
    step: 500
  },
  // Enables the estimated impressions text below the budget field
  impressionLabelEnabled: true,
  cpm: 5,
  // Budget field subheader
  fixedCPMLabel: "",
  // Budget Promotions (NA-8912)
  // Promo percentage should be an integer, it will be converted and applied to the impressions count
  promoPercentage: 20,
  // In order to work, start and end dates must be in MM/DD/YYYY format
  // Single digits will work fine (ie 1 for January)
  promoStartDate: "",
  promoEndDate: "",

  // TARGETING
  // All targeting options are in the format of:
  // label: Visual label in the UI
  // Key: corresponding segment or site ID in the hub admin
  // Audience Targeting
  audiences: [
    { label: "Cooking (JustFood)", key: "6658a6dadc014b349a1a804ff2d7ced3" },
    { label: "Female Forum", key: "f8340354e81a4163965838ea11f5409c" },
    {
      label: "Female Lifestyle (Ounousa, Jamalouki, Nawa3em)",
      key: "2801d97388114c6f8540f493509f834b"
    },
    { label: "Luxury (Gheir)", key: "6980ceba885c490a8ae3b35eee528e6d" },
    { label: "Male Lifestyle", key: "d5e11a905fe54c51b972dc508f234d97" },
    {
      label: "Motoring (StriveMe, Motory, Motorsmotion)",
      key: "bfb7fc26998946d88fe1565c83adb73c"
    },
    { label: "News", key: "c8c1923ee3a14c3c98f934be39802da6" },
    { label: "Parenting (Supermama)", key: "fdb450f70ffd40dba1f7009cd44a6855" },
    {
      label: "Social News (Step Feed)",
      key: "c8f60b1c6957499c8df3c91b2b4acf89"
    },
    {
      label: "Sport (Kooora, Eurosport, Dawri Plus)",
      key: "9dce1133b346428fad730c8a078d409e"
    },
    {
      label: "Trending Topics (Mawdoo3)",
      key: "b5495e863fc3443ea928e02872463fc7"
    },
    { label: "Video (Oln.tv)", key: "28cde484ffed475886f7ec7e7171dd24" }
  ],
  audienceEnabled: true,
  // Used as the UI Header for the audience targeting section
  audienceTitle: "Content",

  // Interest Targeting
  interests: [
    { label: "Arts & Entertainment", key: "1d179b90f6c745ab814e3873450589a1" },
    { label: "Autos & Vehicles", key: "90361863c1334a7d9142cc1ebe45ec7d" },
    {
      label: "Beauty, Fitness & Health",
      key: "d3b461ad96bb40d0809f09a1292eb335"
    },
    {
      label: "Business, Finance & Industrial",
      key: "619fefec438f41fd85591b27d73a94ed"
    },
    {
      label: "Computers & Electronics",
      key: "a2e6437ea41c446a9636b8dca4230675"
    },
    { label: "Food & Drink", key: "867adf7b6b1a438c817206438282ea9a" },
    {
      label: "Home, Garden & Real Estate",
      key: "4d7361a7335b481483639c6c36accd5c"
    },
    { label: "Law & Government", key: "d102a1950aad474ebc17e818cde7be59" },
    { label: "News", key: "7cd38556a7674a6f9d32d19ff4964d85" },
    { label: "Shopping & Retail", key: "5bf5a0131f8b4bfb917f1a00b20d2b63" },
    {
      label: "Sports, Hobbies & Leisure",
      key: "c2545cf341d14bf58c58935eee572985"
    },
    {
      label: "Travel, Accommodation & Transportation",
      key: "adbac100f68e4e7a8bbe6db2c31eea74"
    }
  ],
  interestEnabled: false,

  // Age and  Gender Targeting
  ages: [
    { label: "18-25", key: "f358bb81742146389f46172c3342e280" },
    { label: "26-35", key: "3af4cd27f3ff4d77b162c5e908da7742" },
    { label: "36-45", key: "4db7421afb394a29aa0e5810e5f48831" },
    { label: "46-55", key: "cb04985217714a86bf73b6e9ec8b7ff6" },
    { label: "56+", key: "fdd0059f0fe24de4bc3b245c0cbc31bf" }
  ],
  ageEnabled: false,
  genders: [
    { label: "Male", key: "925caf2163974953b330893dd391dc8b" },
    { label: "Female", key: "ee19b488a59242b78902f09890b4c15d" }
  ],
  genderEnabled: false,

  // Site Targeting
  // NA-9064 - turn on site grouping functionality
  siteGroupEnabled: true,
  sites: [
    {
      group: "Regional",
      sites: [
        { label: "Al Arabia", key: "e24fda02199847ed9ba5e2399ac5165e" },
        { label: "Al Hayat", key: "3334f2c6b39b4b078bc3b9550490965e" },
        { label: "Al Qabas", key: "2a51566e58324768b7acf429533516ad" },
        { label: "Albayan", key: "cbb32edbdffb4b60babea3706dc61ccb" },
        { label: "Annahar", key: "c2a7974d7011414d9b90bad6a4224ee3" },
        { label: "Chababs", key: "eb509e9bd8b646b98ef72601d735685c" },
        { label: "Dawriplus", key: "10ad61b253ec4ac6a700b77cd52fa450" },
        { label: "Dubai Post", key: "9b7b0066c3444d3f83a981140327e0cc" },
        { label: "Emaratalyoum", key: "f36d11a8b9e24056b84163be13c8aefe" },
        { label: "Emirates 24/7	", key: "6757823850734b908e39767bdf1d81fc" },
        { label: "Eurosport", key: "922501e70c634aa38dae62f09c06362f" },
        { label: "Gheir", key: "ffeeb03260eb4eaf94e0301dc5de09cf" },
        { label: "Jamalouki", key: "11f965c7c5834110a86asit93c5ac5115c4" },
        { label: "Kooora", key: "c6cffff4171648edb163eb41eb3a79a4" },
        { label: "LahaMag", key: "70cee55ff09949cc89e04dd50574382d" },
        { label: "L'Orient Le Jour", key: "be057b74933e446d9b8e9ca35f6d6cf5" },
        { label: "Mawdoo3", key: "088b8fce3d3a4356b2a2a3b76bbfe357" },
        { label: "MBC", key: "efbf2106b8814fb4a2dd17d5dcf42e53" },
        { label: "MotorsMotion", key: "1f58b987d58b47c78dfeddbb45ff4dac" },
        { label: "Motory", key: "1e13c875d4e84441bac6ab2bd39ae738" },
        { label: "Mubasher", key: "4ab5860e6f1e4f4c96833adaaeb8ab34" },
        { label: "Nawa3em", key: "cdc9c28224e146948aab9b023bfcafd5" },
        { label: "Ounousa", key: "950ab258d03744c2aeba8cdf23ebb806" },
        { label: "Pulse", key: "f917dfe0b3cf474eb402f0fa6c64a0d8" },
        { label: "Ra2ed", key: "5392104307cf4634b5697284c137a0c0" },
        { label: "Sabq", key: "769bfc46f2a142858106fb09ab8aa337" },
        { label: "StriveMe", key: "7f50e0dac7f9486ca94ad3eb2c02f989" },
        { label: "Supermama", key: "9dd6d8dd436c4c9b90364f6977e0bc56" }
      ]
    },
    {
      group: "Global",
      sites: [
        {
          label:
            "Business (e.g. USAToday Money, Investing Channel, Investopedia, Business Insider, WSJ, etc)",
          key: "587d462ddc574406b9faff5d1c2f15ac"
        },
        {
          label:
            "Entertainment (e.g. Hollywood Life, Bustle, Complex, Polygon, IGN, Lad Bible, People, etc)",
          key: "e43aca85b41f40988396a6c6238b6626"
        },
        {
          label:
            "Fashion & Beauty (e.g. Byrdie, Brides, InStyle, Stylecaster, etc)",
          key: "48176bf43d844c6ebf1e23fae3face5f"
        },
        {
          label:
            "Food (e.g. Eater, Food Network, All Recipes, Eating Well, Leites Culinaria, etc)",
          key: "dfee5b7b175141d581e526b0d4c3a0e1"
        },
        {
          label: "Health (e.g. Healthline, Very Well, Health, Parents, etc)",
          key: "e58d5d4c5568405eb7552082d12c4f29"
        },
        {
          label:
            "Lifestyle (e.g. SheKnows, Curbed, Cheddar, Weather, TheSpruce, Fusion TV, etc)",
          key: "72e0979a79f54caca12436c09ffdde94"
        },
        {
          label:
            "General Consumer (e.g. USAToday, NY Post, Buzzfeed, Vox, The Washington Post, MailOnline, Time, CNN, etc)",
          key: "306ce819ad99434686a8775eb93107a2"
        },
        {
          label:
            "Sports (e.g Sportswire, SB Nation, ESPN, MLB, Sports Illustrated, Bleacher Report, etc)",
          key: "f649f863ad414b67a80a5b74cd17af26"
        },
        {
          label:
            "Technology (e.g. The Verge, Recode, MacRumors, Mashable, , etc)",
          key: "f30ff741c14b4107b6731ed08eaad697"
        },
        {
          label:
            "Travel (e.g. TimeOut, TripSavvy, Travel Channel, Travel and Leisure, etc)",
          key: "3cb90522e56e415f92b190215bfe13ba"
        }
      ]
    }
  ],
  siteEnabled: true,

  // Geo Targeting
  geos: [
    { label: "Bahrain", key: "76d2a0048e764d3c91454eea0dd6ae7e" },
    { label: "Egypt", key: "45eaa8a802a04953a14a21d853bb41a6" },
    { label: "Iraq", key: "5000acb0e03549169e4c2230bc678bd9" },
    { label: "Jordan", key: "0b3d2788af1544e3b432482d52518f43" },
    { label: "Kuwait", key: "98e80f6ec0fd434bb10a94c1ad10bfba" },
    { label: "Lebanon", key: "79b9a989881d492ca1f2d6cb959519a5" },
    { label: "Morocco", key: "52ce1fa204484e3ca937ba062c29a601" },
    { label: "Oman", key: "9745e1a968bc4039a503a7eaa400c6b6" },
    { label: "Palestine", key: "bae799592b484a06a16dd66652709c2d" },
    { label: "Qatar", key: "ac05c2de876b41368138009298646b6f" },
    { label: "Saudi Arabia", key: "a8094f957d834bd19f897093cd572c5c" },
    { label: "UAE", key: "b0c58195be3547c8ba38e1aa77415779" }
  ],
  geoEnabled: true,

  // ADDITIONAL INFORMATION
  additionalInformationEnabled: true,
  additionalInformationSubheader:
    "Provide click, impression trackers or any other instructions.",

  // PAYMENT

  stripeEnabled: true,
  accountIdEnabled: true,
  // Text rendered below the payment field
  paymentLabel:
    "You will be charged from Polar Inc. on your credit card in USD for the full budget on the start date of your campaign.",
  // Enable account ID payment option, if both this and stripe are true, a switcher will be used
  accountId: true,
  // Account ID field Subheader
  // Only used when stripe is not enabled
  accountIdSubheader: "",

  // TERMS AND CONDITIONS
  tacEnabled: true,
  // Enables rendering T&C as HTML
  // If true, the T&C must be defined as an HTML object in the htmlTermsAndConditions object inside TermsAndConditionsModal.jsx
  tacHTML: true,
  // Used as the terms and conditions text when tacHTML is false
  termsAndConditions:
    "This Order is governed by the IAB's Standard Terms and Conditions for Internet Advertising for Media Buys.",

  // Footer displayed below submit button
  partnerMessage: "For questions, contact direct@polar.me"
};
