module.exports = {
    // FORM LEVEL CONFIG
    // Required for backend - Format: <RoleID>:<Role API Token>
    roleToken: "13908:b58db56c077e4d4ba3a48fe82b703094",
    // Required for backend - Marketplace unique hash
    // https://mediavoice.com/admin/distribution/marketplace/
    marketplace: "a06e5a019504438f8fa87e2e09d78fce",
    // Must be a stripe supported currency code
    // See: https://stripe.com/docs/currencies
    currency: "usd",
    // Used as the page title
    pageTitle: "SPH Order Form",
    // Header image clickout
    imageLink: "https://polar.me/sph",
    toEmails: [],

    // CAMPAIGN DETAILS
    // Turns on the social post ownership checkbox (NA-8879)
    socialOwnershipCheckboxEnabled: false,
    previewToolEnabled: false,
    previewToolURL: "",
    // Dates
    dateSliderEnabled: true,
    dateSliderSettings: {
        labelBeforeValue: "",
        labelAfterValue: " day",
        color: "grey",
        start: 3,
        min: 3,
        max: 7,
        step: 1,
    },
    durationSliderSettings: {
        labelBeforeValue: "",
        labelAfterValue: " day total",
        color: "grey",
        start: 7,
        min: 3,
        max: 30,
        step: 1,
    },
    // Label shown below date fields
    dateSubheader: "",
    // Budget/CPM
    budgetSliderEnabled: true,
    budgetSliderSettings: {
        labelBeforeValue: "$",
        labelAfterValue: " total spend",
        color: "grey",
        start: 1000,
        min: 1000,
        max: 50000,
        step: 1000,
    },
    // Enables the estimated impressions text below the budget field
    impressionLabelEnabled: true,
    cpm: 6,
    // Budget field subheader
    fixedCPMLabel: "",
    // Budget Promotions (NA-8912)
    // Promo percentage should be an integer, it will be converted and applied to the impressions count

    // TARGETING
    // All targeting options are in the format of:
    // label: Visual label in the UI
    // Key: corresponding segment or site ID in the hub admin
    // Audience Targeting
    audiences: [
        {label: "Arts & Entertainment", key: "878b31c51f3840648da028cb2a201a87"},
        {label: "Banking, Finance & Investing", key: "e82ba3d9d186431eb823aa99dd75f01a"},
        {label: "Family & Lifestyle", key: "95f38202cce24de2b8cad24993cb0bf9"},
        {label: "Food, Drink & Recipes", key: "c15a60a7e5cb4f20b0acfb2e648f2b12"},
        {label: "Government & Policy", key: "ec7727578d7d4a5dab28a766f1069622"},
        {label: "Science & Health", key: "b6b8b1164ba942fa9d2f2b28f0bcbee7"},
        {label: "Sports", key: "fe330ba7ba0c4bb492690308181821f5"},
        {label: "Technology", key: "de96f2d933e44f018b466c4e3a0be654"},
        {label: "Travel", key: "2d745fe4d4724f94b78a9249f59659d9"},
    ],
    audienceEnabled: true,
    // Used as the UI Header for the audience targeting section
    audienceTitle: "Content",

    // Interest Targeting
    interests: [
        { label: "Arts, Entertainment & Pop Culture", key: "7444b8bb1cb449fdb210de5273c32849" },
        { label: "Autos & Vehicles", key: "2e1f20b8e2f84a5b80e007c56555d465" },
        { label: "Beauty & Fitness", key: "6a9d11859259417a9f21c3cdc63b41af" },
        { label: "Business, Finance & Industrial", key: "c3991393cfdb4d43a62f62b3a867b739" },
        { label: "Computers & Electronics", key: "3861c3b13f78465eaaac4a2e047a9d74" },
        { label: "Food, Drink & Health", key: "43636ba89776427bb8d721b2e3bb0b72" },
        { label: "Home, Garden & Real Estate", key: "4d086b642ab245dfa23b9440e76cd621" },
        { label: "Law & Government", key: "0a509ce304264ba29e52470bf5bf5feb" },
        { label: "News", key: "33a2538dc76c49e4b34a696ba776dc73" },
        { label: "Shopping & Retail", key: "d379135ddc70433da252b68db8a4ff7d" },
        { label: "Sports & Hobbies", key: "a80a5402ad664830b375458f82cdee88" },
        { label: "Travel & World Localities", key: "4999e29ef7414915ade1296879df1131" },
    ],
    interestEnabled: false,

  // Age and  Gender Targeting
  ages: [
    { label: "18-25", key: "6b0a6f69896b453d96d95f3304e1cb80" },
    { label: "26-35", key: "b91c764b176e431e946a328ba12f30c5" },
    { label: "36-45", key: "98d95154f9d449ab905b62e80d82f8c0" },
    { label: "46-55", key: "8a70ba3b3e854d14a9c6f000cff9859b" },
    { label: "56+", key: "d9b209846058451c8de8de6b918d1ae5" }
  ],
  ageEnabled: true,
  genders: [
    {label: "Male", key: "33bc0ffa2d914242b7d8f2e326025f0f"},
    {label: "Female", key: "2903a4fb5ff1403db49b419e7d8f9017"},
  ],
  genderEnabled: true,

    // Site Targeting
    sites: [
{
    group: "Local",
    sites: [
        {label: "8 Days", key: "e497995ef7fd46748f41626478aa5066"},
        {label: "8 World", key: "f9bb8d1c233446aba730d13136f1db31"},
        {label: "Asia One", key: "c36b1d1585e14b40abe93651e211b9e3"},
        {label: "Berita", key: "202cedba74554d4493182be754913129"},
        {label: "Berita Harian", key: "522d0ee9f5884495af5c5e1626102533"},
        {label: "Business Insider Singapore", key: "df50fcc0cf0b4b13aa95a885931d002d"},
        {label: "The Business Times", key: "95ec3dd04ad34618902ab7713e2e9084"},
        {label: "CNA", key: "bd7cac52fe2f42068d7b788896f48cde"},
        {label: "The Newpaper", key: "b666e9c0a6aa4c30a073af33d32dff93"},
        {label: "Seithi", key: "9cf9c2d95f124399a8b984090eee69f4"},
        {label: "The Straits Times", key: "deb4ca32dcd049808e926588bcc48159"},
        {label: "Stomp", key: "5ddc13c41ce94666bd4e9461daa4f606"},
        {label: "TODAY", key: "927cbfb5ec9f40f1a4b18d8c5c9344c5"},
        {label: "Toggle", key: "d59854ea14914143ac20459b4c98e1ba"},
        {label: "ZaoBao", key: "9fb9f740975b416888e5d00ff2fdc9c8"},
    ],
 },
 {
    group: "Global",
    sites: [
        {
          label:
            "Business (e.g. USAToday Money, Investing Channel, Investopedia, Business Insider, WSJ, etc)",
          key: "ac8472dfcf3b4e1d99c960bac804838b"
        },
        {
          label:
            "Entertainment (e.g. Hollywood Life, Bustle, Complex, Polygon, IGN, Lad Bible, People, etc)",
          key: "b8c23edc681946a599ddcbd86a162fc5"
        },
        {
          label:
            "Fashion & Beauty (e.g. Byrdie, Brides, InStyle, Stylecaster, etc)",
          key: "215261398040400489741b950af07652"
        },
        {
          label:
            "Food (e.g. Eater, Food Network, All Recipes, Eating Well, Leites Culinaria, etc)",
          key: "b7164bb131474cb8833558cde363ad48"
        },
        {
          label:"General Consumer (e.g. USAToday, NY Post, Buzzfeed, Vox, The Washington Post, MailOnline, Time, CNN, etc)",
          key: "12216734f028474392c69a97c11a479f"
        },
        {
          label: "Health (e.g. Healthline, Very Well, Health, Parents, etc)",
          key: "47ba6865d86b49bdb5fe5849d3d9f614"
        },
        {
          label:
            "Lifestyle (e.g. SheKnows, Curbed, Cheddar, Weather, TheSpruce, Fusion TV, etc)",
          key: "0410d69d1c1a422386db0af5c3235208"
        },
        {
          label:
            "Sports (e.g Sportswire, SB Nation, ESPN, MLB, Sports Illustrated, Bleacher Report, etc)",
          key: "8490b426bb634c049b4821dbcbb744df"
        },
        {
          label: "Technology (e.g. The Verge, Recode, MacRumors, Mashable, , etc)",
          key: "8835c2ee1701461aa1b04f49a70775d4"
        },
        {
          label:
            "Travel (e.g. TimeOut, TripSavvy, Travel Channel, Travel and Leisure, etc)",
          key: "db0afeb48bae462b9d3453314902a35f"
        }
      ],
 }],
    siteGroupEnabled: true,
    siteEnabled: true,

    // Geo Targeting
    geos: [
      {group: "Singapore", geos: [
        {label: "National (Singapore)", key: "2968e75b0daa46ae83db301110cd91fd"},
        {label: "Chinatown", key: "be91d4150fb741ebbdfc9ebeb8b29f7f"},
        {label: "Colonial District", key: "47cfc7d3ae734604a102d6f181290194"},
        {label: "Kampong Glam", key: "c2035cd1bb6f45d6a0b90cbd11dbb364"},
        {label: "Little India", key: "79e6be032b424b999b647a63da9f7aa7"},
        {label: "Marina Bay", key: "7b9b6a08982b466e9b2ff166b4cbde79"},
        {label: "Orchard Road", key: "3c20e98abe15422691cb496c5a254d29"},
        {label: "Robertson Quay", key: "50ef54658b6d49c49818b6d392cd56c0"},
        {label: "Sentosa Island", key: "afedf2d26e7e497cbeb0d6162049f07f"},
      ]},
      {group: "Regional", geos: [
        {label: "Hong Kong", key: "e2c3029e89d4403e8190609508fee6d3"},
        {label: "Indonesia", key: "1f3b3e7603454d2ea157039b3ec4ff6c"},
        {label: "Malaysia", key: "54c56df9e57c456d8449e886933a39f0"},
        {label: "Philippines", key: "933f32d6239c4c1a87495e568206985d"},
        {label: "Thailand", key: "206c3c47b76f42e9934dab1b5bca8f9e"},
        {label: "Vietnam", key: "cdc321337e2c48dc9bf02d76a568c923"},
      ]},
      {group: "Global", geos: [
        {label: "Australia", key: "02f2d99baaa24c2cb26633a114a86fbb"},
        {label: "Belgium", key: "6a1afc020cdc47aca31bfed87ce28e76"},
        {label: "Brazil", key: "cd232e32f1c241e58c7ccc171f34a2e7"},
        {label: "Canada", key: "a617f1e06d5f47dd9c53dea45cb99788"},
        {label: "France", key: "f9cf2d781c1c47898e80b08c00da7237"},
        {label: "Germany", key: "4fc0435856964425a14c526c85189643"},
        {label: "Italy", key: "cf2ebc223bdd477da268d52ceafc4340"},
        {label: "Mexico", key: "991ce77113394312b38a53f0b442ad42"},
        {label: "Netherlands", key: "fd8a467abd2d47b6a992933888baa2e7"},
        {label: "New Zealand", key: "f1d21f7e6c9843e98d2956ac3d3c25ec"},
        {label: "Portugal", key: "b42cb5d562a44a3f9559a29f82598735"},
        {label: "South Africa", key: "14bff484b08047b8abf065c1d670005d"},
        {label: "Spain", key: "1366a94ad18e452d9c0759b980930289"},
        {label: "UAE", key: "1ae5167e668e44d49b848628066db003"},
        {label: "United Kingdom", key: "ee18fcf3cd3e4d2593f282a489a38f66"},
        {label: "United States", key: "8375826b4249469a97d20fb98ce02b28"},
      ]}
    ],
    geoEnabled: true,
    geoGroupEnabled: true,
    
    // ADDITIONAL INFORMATION
    additionalInformationEnabled: true,
    additionalInformationSubheader: "Provide trackers or any other instructions.",


    // PAYMENT
    stripeEnabled: true,
    accountIdEnabled: true,
    // Text rendered below the payment field
    paymentLabel:
      "You will be charged from Polar Inc. on your credit card in USD for the full budget on the start date of your campaign.",
    // Enable account ID payment option, if both this and stripe are true, a switcher will be used
    accountId: true,
    // Account ID field Subheader
    // Only used when stripe is not enabled
    accountIdSubheader: "",

    // TERMS AND CONDITIONS
    tacEnabled: true,
    // Enables rendering T&C as HTML
    // If true, the T&C must be defined as an HTML object in the htmlTermsAndConditions object inside TermsAndConditionsModal.jsx
    tacHTML: true,
    // Used as the terms and conditions text when tacHTML is false
    termsAndConditions: "This Order is governed by the IAB's Standard Terms and Conditions for Internet Advertising for Media Buys.",

    // Footer displayed below submit button
    partnerMessage: ""
}
