export default {
  // Contact Info
  'Contact': {
    en: 'Contact',
    fr: 'Contact'
  },
  'Name': {
    en: 'Name',
    fr: 'Nom'
  },
  'First': {
    en: 'First Name',
    fr: 'Prénom'
  },
  'Last': {
    en: 'Last Name',
    fr: 'Nom'
  },
  'Email': {
    en: 'Email',
    fr: 'Email'
  },
  'Email Error': {
    en: 'Please enter a valid email',
    fr: "S'il vous plaît entrer un email valide"
  },
  'Company': {
    en: 'Company',
    fr: 'Société'
  },
  // Campaign Details
  'Campaign': {
    en: 'Campaign',
    fr: 'Campagne'
  },
  'Social Post URL(s)': {
    en: 'Social Post URL(s)',
    fr: 'URL de publication sociale',
  },
  'Social Post Subheader': {
    en: 'Insert social post URL(s) and separate multiple by commas.',
    fr: 'Insérez les URLs de publications sociale, séparez les avec des virgules.'
  },
  'Social Post Permission': {
    en: 'I have permission to use the social post asset(s).',
    fr: "J'ai la permission d'utiliser le ou les actifs de publication sociale."
  },
  'Ad Previews': {
    en: 'Ad Previews',
    fr: 'Aperçus des annonces'
  },
  'Preview Before': {
    en: 'Get instant ad previews ',
    fr: 'Obtenez des aperçus instantanés des annonces '
  },
  'here': {
    en: 'here',
    fr: 'ici'
  },
  'Destination Page': {
    en: 'Destination Page',
    fr: 'Page de destination'
  },
  'Destination Subheader': {
    en: 'This is the destination page of the ad. Web pages, map, telephone, and email links are supported.',
    fr: "Il s’agit de la page de destination de votre annonce. Les pages web, numéros de téléphone et adresses email sont acceptées."
  },
  'CTA Text': {
    en: 'Call-To-Action Button Text',
    fr: 'Texte du bouton “call to action”'
  },
  'CTA Text Subheader': {
    en: 'This is the button text that will appear in the ad.',
    fr: "Il s’agit du texte qui apparaîtra sur le bouton d’action."
  },
  // Dates
  'Start Date': {
    en: 'Start Date',
    fr: "Date de début"
  },
  'End Date': {
    en: 'End Date',
    fr: 'Date de fin'
  },
  'Duration': {
    en: 'Duration',
    fr: "Durée"
  },
  'Campaign End': {
    en: 'Your campaign will end on ',
    fr: 'Votre campagne se terminera le '
  },
  // Budget
  'Budget': {
    en: 'Budget',
    fr: 'Budget'
  },
  'Impression Label': {
    en: 'Your total booked impressions is {formattedImpressions} and total spend is {before}{formattedBudget}',
    fr: "Votre nombre total d’impressions sera de {formattedImpressions} pour un budget de {before}{formattedBudget}"
  },
  'VAT': {
    en: '({budgetWithVAT} with VAT)',
    fr: '({budgetWithVAT} avec VAT)'
  },
  'Promo Label': {
    en: '{extraImpressions} bonus impressions added for no additional cost (receive {promoPercentage}% bonus promotion until {promoEndDate}).',
    fr: "{extraImpressions} impressions ajoutées pour gratuit (recevoir {promoPercentage}% impressions supplémentaires jusqu'au {promoEndDate})"
  },
  // Targeting
  // TODO if necessary
  // Additional Info
  'Additional Information': {
    en: 'Additional Information',
    fr: 'Information complémentaire'
  },
  // Payment
  'Payment': {
    en: 'Payment',
    fr: 'Paiement'
  },
  'Invoice': {
    en: 'Invoice',
    fr: "Facture d'achat"
  },
  'Card': {
    en: 'Credit Card *',
    fr: 'Carte de crédit *'
  },
  'ID': {
    en: 'Account ID',
    fr: 'Identifiant de compte'
  },
  // Terms & Conditions
  'Terms and Conditions': {
    en: "Terms and Conditions",
    fr: '"Terms and Conditions"',
  },
  'I accept the': {
    en: "I accept the",
    fr: "J’accepte les"
  },
  // Submit
  'Submit': {
    en: 'Submit',
    fr: 'Envoyer'
  },
  // Errors
  'Submission Error': {
    en: `There's an error submitting your order. Please try again or contact <a> for assistance.`,
    fr: `C'eté une erreur dans votre remise. Réessayer ou contacter <a> pour assistance.`
  }
}